import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { authLogin } from "../../utils/auth_service/AuthServiceHelper";
import { useSelector, useDispatch } from 'react-redux';
import { closeLoginModal } from '../../store/actions/LoginModalActions';
import { tokenRefreshed } from '../../store/actions/RefreshTokenAction';
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import history from '../../history';
import styles from './Login.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { closeAlertModal } from '../../store/actions/AlertModalActions';

function Login() {
 
    const dispatch = useDispatch();
    const modalToggle = useSelector(state => state.login.loginModalToggle)
    const apiService = new AxiosService();
    const [invalidCredentials, setInvalidCredentials] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const path = window.location.pathname.split('/')[2];
    const [formValues, setFormValues] = useState({ email: '', password: '' });
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [forgotPasswordToggle, setForgotPasswordToggle] = useState(false);
    const [varificationMailToggle, setVarificationMailToggle] = useState(false)
    const [disabledUserEmail, setDisableduserEmail] = useState("")
    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );

    const [errorMessage, setErrorMessage] = useState("")

    function validateForm() {
        if(forgotPasswordToggle || varificationMailToggle){
            return validEmailRegex.test(formValues.email);
        } 
        else{
            return validEmailRegex.test(formValues.email) && formValues.password.length >= 6;
        }
    }

    /**
     * Form validations - email & password, set form input values
     */

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: value.length > 0 && ""})
    }

    function handleValidation(event){
        const { name, value } = event.target;
        switch (name) {
            case "email":
                setFormErrors({
                    ...formErrors,
                    email:
                        value.length > 0
                            ? validEmailRegex.test(value)
                                ? ""
                                : "Please enter a valid Email"
                            : "Email is required"
                });
                break;
            case "password":
                setFormErrors({
                    ...formErrors,
                    password:
                        value.length > 0
                            ? value.length > 5
                                ? ""
                                : " Password must be 6 characters"
                            : " Password is required"
                });
                break;
            default:
                break;
        }
    }

    function resetFormFields() {
        setFormValues({ ...formValues, email: "", password: "" });
    }

    /**
     * Form submit on validation - auth service call
     * on success redirect to manage subscripitons page
     */

    function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        if(forgotPasswordToggle){
            const data = {email: formValues.email.toLowerCase()}
            apiService.forgetPassword(data).then(response => { 
                setIsLoading(false);
                setInvalidCredentials("");
                resetFormFields();
                setErrorMessage("")
                handleModalClose()
                toast.success("Reset password link has been sent successfully",  {
                    autoClose: 3000,
                })
                setTimeout(()=>{
                    history.push("/home")
                    setForgotPasswordToggle(false);
                    window.location.reload();
                }, 3000)
            }).catch(error => {
                const errorData = error?.response?.data;
                setIsLoading(false);
                setErrorMessage(decodeURIComponent(errorData?.error_description))
                setInvalidCredentials("");
                // toast.error(errorData.error_description ? errorData.error_description : "Something went wrong")
            })
        } else if(varificationMailToggle) {
            const data = {email: formValues.email.toLowerCase()}
            apiService.resendVerificationLink(false, data).then(response => { 
                setIsLoading(false);
                setInvalidCredentials("");
                resetFormFields();
                setForgotPasswordToggle(false);
                setVarificationMailToggle(false)
                handleModalClose()
                toast.success("Reset password link has been set successfully", {autoClose: 3000})
            }).catch(error => {
                const errorData = error.response.data;
                setIsLoading(false);
                setInvalidCredentials("");
                resetFormFields();
                // dispatch(closeLoginModal())
                toast.error(errorData.error_description ? decodeURIComponent(errorData.error_description) : "Something went wrong", {autoClose: 3000});
            })
        } else {
            const params = new URLSearchParams();
            params.append("userName", formValues.email.toLowerCase());
            params.append("password", formValues.password);
            params.append("grantType", "password");
            const config = {
                url: "api/v1/auth/getToken",
                method: "post",
                data: params
            };
            authLogin(config)
                .then(response => {
                    setIsLoading(false);
                    setInvalidCredentials("");
                    resetFormFields();
                    handleModalClose()
                    dispatch(tokenRefreshed());

                    if(path && (path === 'Register' || path === 'registrationConfirm')){
                        history.push("/home")
                    }
                    setTimeout(()=>{
                        window.location.reload();
                    },1000)
                    toast.success("Successfully Logged In!",  {
                        autoClose: 3000,
                    });

                })
                .catch(error => {
                    setIsLoading(false);
                    const errorMessage =  error.response;
                    switch (errorMessage && errorMessage.status) {
                        case 400:
                        case 401:
                            setInvalidCredentials(errorMessage.data.error_description);
                            resetFormFields();
                            if (errorMessage.data.error_description === "User is disabled") {
                                setDisableduserEmail(formValues.email)
                                setTimeout(() => {setVarificationMailToggle(true)}, 500)
                            }
                            break;
                        case 406: 
                            setInvalidCredentials(errorMessage.data.error_description);
                            resetFormFields();
                            if (errorMessage.data.error_description === "User is disabled") {
                                setDisableduserEmail(formValues.email)
                                setTimeout(() => {setVarificationMailToggle(true)}, 500)
                            }
                            break;
                        default:
                            break;
                    }
                });
        }
    }

    function handleForgotPassword() {
        setInvalidCredentials("")
        setForgotPasswordToggle(true)
    }

    function handleToggle() {
        setForgotPasswordToggle(false)
        setErrorMessage("")
    }

    function handleVerificationMailToggle () {
        setVarificationMailToggle(false)
        setErrorMessage("")
    }
    
    function handleModalClose () {
        if(path && path.toLowerCase() === "user") {
            return
        } else {
            setInvalidCredentials("")
            resetFormFields()
            setFormErrors({...formErrors, email: "", password: ""})
            setForgotPasswordToggle(false)
            setVarificationMailToggle(false)
            dispatch(closeAlertModal())
            dispatch(closeLoginModal())
        }
    }

    useEffect(() => {

    }, [modalToggle])

    useEffect(() => {
        setFormValues({...formValues, email: disabledUserEmail})
    }, [varificationMailToggle])

    return (

        <Modal show={modalToggle} style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}} dialogClassName={styles.modalWidth}>

            <Modal.Body>
                <img src={process.env.PUBLIC_URL + '/logos/orthopublish-logo.svg'} className="px-5 pt-3 w-100" alt="OrthoPublish logo"></img>
                <p className={styles.logoLabel}>Get your paper ready for submission</p>
                <hr style={{width: "200px"}}/>
                <h4 className="text-center mt-3">{!forgotPasswordToggle ? 'Log in' : 'Forgot password'}</h4>
                {errorMessage && <p dangerouslySetInnerHTML={forgotPasswordToggle ? {__html: errorMessage} : {__html: ""}} className={classNames("text-center text-danger mb-0 mt-3 mx-3", styles.validators)}></p>}
    
                <form className="p-3" onSubmit={handleSubmit}>

                    {invalidCredentials && <div className={styles.validators}><span>{invalidCredentials}</span></div>}
                    {!varificationMailToggle ? 
                        <>
                            <label htmlFor="email">{forgotPasswordToggle ? "Registered Email Address" : "Email"}</label>
                            <InputGroup className="mt-2">
                                <FormControl
                                    aria-label="Username"
                                    className={styles.customFormField}
                                    type="text"
                                    name="email"
                                    value={formValues.email}
                                    placeholder="Enter your login email address"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                    aria-describedby="basic-addon1"
                                    />
                            </InputGroup>
                            <span className={classNames(styles.formErrors, "d-block")}>{formErrors.email}</span>
                        </> 
                        : 
                        <>
                            <li className={styles.listNone}>
                                <span>Check your inbox and spam folder for verification mail.</span>
                            </li>
                            <li className={styles.listNone}>
                                <span>You can request to resend email on your registered email address <strong>{formValues.email}</strong>.</span>
                            </li>
                            <br/>
                        </>
                    }
                    {forgotPasswordToggle ? 
                        <>
                            {!isLoading ? <button className='w-100' disabled={!validateForm()} type="submit">Send password reset link
                            </button> :
                            <button className='w-100'>
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                <span>Sending...</span>
                            </button>}
                            <h6 style={{fontWeight: 400, margin: '0.25rem 0rem'}}>A password reset link will be sent to the email ID linked to your OrthoPublish account</h6>
                            <div className={styles.formFooter}>
                                <span>Remember password. Go back to</span>
                                <button className={styles.customLink} onClick={() => handleToggle()}>Login</button>
                            </div>
                        </>
                    :
                    <>
                    {varificationMailToggle === false ?
                    <> 
                            <label htmlFor="password">Password</label>
                            <InputGroup className={classNames("mt-2", styles.customInput)}>
                                <FormControl
                                    className={styles.customFormField}
                                    type={passwordToggle ? "text" : "password"}
                                    aria-label="Password"
                                    name="password"
                                    value={formValues.password}
                                    placeholder="Enter your password"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                    aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>
                                        {passwordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setPasswordToggle(!passwordToggle)} /> :  <FontAwesomeIcon icon={faEyeSlash} onClick={() => setPasswordToggle(!passwordToggle)} />}
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            <span className={classNames(styles.formErrors, "d-inline")}>{formErrors.password}</span>

                            <div className="mb-3">
                                <span className={styles.customLink} onClick={() => handleForgotPassword()}>Forgot Password?</span>
                            </div>

                            {!isLoading ? 
                                <button className='w-100'
                                    disabled={!validateForm()}
                                    type="submit">Sign in</button>
                                :
                                <button className='w-100' disabled={!isLoading}>
                                    <span>Loading
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                    </span>
                                </button>
                            }
                            <div className={styles.formFooter}>
                                <span>Don't have an account?&nbsp;</span>
                                <Link to="/Register" className={styles.customLink} onClick={() => handleModalClose()}>Create an account</Link>
                            </div>
                            <div className={classNames(styles.customLink, "text-center")}><Link to="/home" onClick={() => handleModalClose()}>Go to Homepage</Link></div>
                        </>
                        :
                        <>
                            {!isLoading ? <button className='w-100' disabled={!validateForm()} type="submit">Send password reset link
                            </button> :
                            <button className='w-100'>
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                <span>Sending...</span>
                            </button>}
                            <div className={styles.formFooter}>
                                <span>Go back to</span>
                                <button className={styles.customLink} onClick={() => handleVerificationMailToggle()}>Login</button>
                            </div>
                        </>
                    }
                    </>}
                </form>
                
            </Modal.Body>
        </Modal>
    )
}

export default Login;