import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import {PageView} from '../../tracking';
import styles from "./PrivacyPolicy.module.scss"

function PrivacyPolicy(){

    useEffect(() => {
        PageView();
    }, [])

    return(
        <Row className={classNames("mt-3 bg-white mx-0 rounded", styles.privacyPolicy)}>
            <Col md={12} className="p-4">
                <h5>PRIVACY NOTICE</h5><hr />
                <div> {/* INTRODUCTION */}
                    <h6>1.	Introduction</h6>
                    <section className='pl-3'>
                        <p>1.1	This privacy notice (<strong>Privacy Notice</strong>) sets out the ways in which we, The British Editorial Society of Bone & Joint Surgery (<strong>we, us, our</strong>), collect and use your personal information when you visit our websites: <a href='https://boneandjoint.org.uk/' target='_blank'> https://boneandjoint.org.uk/</a>, <a href='https://www.orthomedia.org.uk' target='_blank'>www.orthomedia.org.uk</a>, <a href='https://www.orthopublish.org.uk' target='_blank'>www.orthopublish.org.uk</a> and <a href='https://www.orthosearch.org.uk/Home' target='_blank'>www.orthosearch.org.uk/Home</a> (the <strong>“Sites”</strong>).</p>
                        <p>1.2	Please read the following information carefully to understand our practices regarding your personal information and how it will be treated, and your rights in respect of your personal information.</p>
                        <p>1.3	The Sites are not intended for children. We do not knowingly collect or maintain the personal information of children under the age of 18. If you are under the age of 18, please do not access our Sites at any time or in any manner. We will take appropriate steps to delete the personal information of persons under the age of 18.</p>
                    </section>
                </div>
                <div> {/* ABOUT US */}
                    <h6>2.	About Us</h6>
                    <section className='pl-3'>
                        <p>2.1	The British Editorial Society of Bone & Joint Surgery is the data controller in respect of your personal information.</p>
                        <p>2.2	We are a company registered in England under company number 522061, with our registered address at 22 Buckingham Street, London WC2N 6ET, UK.</p>
                        <p>2.3	You can contact us as follows: FAO: Richard Hollingsworth, Managing Director at The British Editorial Society of Bone & Joint Surgery, 22 Buckingham Street, London WC2N 6ET or by email to <a href='mailto:data@boneandjoint.org.uk' className='linkText'>data@boneandjoint.org.uk.</a></p>
                    </section>
                </div>
                <div> {/* INFORMATION WE MAY COLLECT ABOUT YOU */}
                    <h6>3.	Information we may collect about you</h6>
                    <section className='pl-3'>
                        <h6>3.1	Information that you provide to us.</h6>
                        <section className='pl-3'>
                            <p>3.1.1	We will collect any information that you provide to us when you:</p>
                            <section className='pl-3'>
                                <p>(a)	make an enquiry, provide feedback or make a complaint over the phone, by email or our Sites;</p>
                                <p>(b)	sign up to our marketing list;</p>
                                <p>(c)	submit, edit or review manuscripts to us by post, email or via our Sites;</p>
                                <p>(d)	interact with our content such as commenting or reposting video conferences or podcasts;</p>
                                <p>(e)	enter into a contract with us to publish your article;</p>
                                <p>(f)	submit an application to a job vacancy;</p>
                                <p>(g)	subscribe to our journals from our Sites, by post, by email, by fax, by telephone or from our stand at a conference;</p>
                                <p>(h)	create an account on our Sites;</p>
                                <p>(i)	provide your details when you meet us or fill out a form and send back to our office at a conference or event;</p>
                                <p>(j)	attend an event or virtual conference we host;</p>
                                <p>(k)	take part in a survey we conduct.</p>
                            </section>
                            <p>3.1.2	The information you provide to us will include (depending on the circumstances and service used):</p>
                            <section className='pl-3'>
                                <p>(a)	<strong>Identity and contact data:</strong> title, names, addresses, email addresses and phone numbers;</p>
                                <p>(b)	<strong>Account profile data:</strong> if you’re registering for an account you may also provide a username, password, email, orthopaedic specialty, job title, organisation, job function, telephone, postal address, ORCID ID and additional information about your academic and work history, references and any other such similar information as may be required;</p>
                                <p>(c)	<strong>Financial data:</strong> If you are using the Sites to purchase products or services, or filling out a form received in the post, from your association or at a conference, you will also provide payment details, which may include billing addresses, postal address, credit/debit card details and bank account details;</p>
                                <p>(d)	<strong>Employment and background data:</strong> If you are submitting a job application, you may also provide additional information about your academic and work history, projects and research that you are involved in, references and any other such similar information that you may provide us;</p>
                                <p>(e)	<strong>Survey data:</strong> from time to time we might ask if you would be willing to participate in our surveys; if you agree, we will also collect any information that you provide as part of that survey.</p>
                            </section>
                        </section>
                        <h6>3.2	Information we collect about you.</h6>
                        <section className='pl-3'>
                            <p>3.2.1	<strong>Information contained in correspondence.</strong> We will collect any information contained in any correspondence between us. For example, if you contact us using a query button on our Sites or by email or telephone, we will keep a record of that correspondence;</p>
                            <p>3.2.2	<strong>Information transmitted on the Sites.</strong> We will collect information that you upload or post to your Sites account and/or any correspondence or interactions that you may have with other Sites account holders;</p>
                            <p>3.2.3	<strong>Sites usage data.</strong> We will collect information about your interactions with the Sites, including information such as login data, IP address, page views, searches, requests, orders, pre-approvals, confirmations, agreements between you and other Sites users and other actions on the Sites;</p>
                            <p>3.2.4	<strong>Events/Conference data:</strong> We may capture images of you when we take photographs of or film an overview of an event, or if we record video conferences or virtual events. Your image may be included in an image or video of the audience at an event you attend or your image may be specifically captured if you are a speaker at an event. We will endeavour to let you know, for example by notices at the event or where applicable contacting you for a release, if we will be photographing, filming or recording. If you do not wish for your image to be used by us, you can let us know by using the contact details at the top of this Privacy Notice;</p>
                            <p>3.2.5	<strong>Technical data.</strong> We will also collect certain information about how you use our Sites and the device that you use to access our Sites, even where you have not created an account or logged in. This might include your geographical location, device information (such as your hardware model, mobile network information, unique device identifiers), the data transmitted by your browser (such as your IP address, date and type of the request, content of the request regarding the specific site, time zone settings, access status/HTTP status code, volume of data transmitted, browser type and version, language settings, time zone settings referral source, length of visit to the Sites, date and time of the request, operating system and interface), number of page views, the search queries you make on the Sites, publication metrics, and similar information. This information may be collected by a third-party Sites analytics service provider on our behalf and/or may be collected using cookies or similar technologies. For more information on cookies please read the section headed <strong>Cookies</strong> below.</p>
                        </section>
                        <p>We do not collect any special categories of personal data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
                        <h6>Information we receive from third parties</h6>
                        <section className='pl-3'>
                            <p>3.2.6	In certain circumstances, we will receive information about you from third parties. For example,</p>
                            <section className='pl-3'>
                                <p>(a)	<strong>Fraud detection agencies.</strong> Where permitted or required by law, we may receive information about you, including demographic data or fraud detection information from third party service providers and/or partners;</p>
                                <p>(b)	<strong>Social media plugins.</strong> We currently use social media plugins from the following service providers who are based both inside and outside the EU: Facebook, Google+, X (formerly Twitter), LinkedIn.  By providing your social media account details you are authorising that third-party provider to share with us certain information about you;</p>
                                <p>(c)	<strong>Confirmation of publication history.</strong> We may use third party providers to verify information provided by you in connection with any manuscript you submit to us for publication. For example, we will use third-party databases or websites to confirm your publication history;</p>
                                <p>(d)	<strong>Service providers.</strong> We may collect personal information from our Sites developer, IT support provider, payment services providers, manuscript submission system, email marketing systems, survey providers and sales agents;</p>
                                <p>(e)	<strong>Referrals.</strong> We will receive information about you from third parties if they are referring you to us for publication. For example, if you are the co-author of a manuscript, your co-author will be required to provide us with information about you;</p>
                                <p>(f)	<strong>Employers and referees.</strong> If you are a job applicant we may contact your recruiters, current and former employers and/or referees to provide information about you and your application;</p>
                                <p>(g)	<strong>Referring organisations.</strong> We receive information about you from third parties that are referring you to us. For example, if you are the co-author or institutional contact of an author submitting a manuscript, the submitting author will be required to provide us with information about you. If you are the referring party, you warrant that you have obtained the necessary consent to share this information with us;</p>
                                <p>(h)	<strong>Publicly available sources.</strong> We currently use publicly available sources such as Companies House, for instance to carry out identity and compliance checks;</p>
                                <p>(i)	<strong>Association agreements.</strong> We receive information (name, email, postal address) about you if you are a member of an orthopaedic association that provides our subscription journals as a membership benefit, or in some cases, if one of our journals is an official journal of your orthopaedic association;</p>
                                <p>(j)	<strong>ORCID ID.</strong> If you are an author, you may be required to provide your ORCID ID. By providing your ORCID ID you are authorising that third-party provider to share with us certain information about you.</p>
                            </section>
                            <p>3.2.7	We might also receive information about you from third parties if you have indicated to such third party that you would like to hear from us.</p>
                        </section>
                    </section>
                </div>
                <div> {/* INFORMATION WE MAY COLLECT ABOUT YOU */}
                    <h6>4.	We will use your information for the purposes listed below either on the basis of:</h6>
                    <section className='pl-3'>
                        <p>4.1.1	performance of your contract with us and the provision of our services to you;</p>
                        <p>4.1.2	your consent (where we request it);</p>
                        <p>4.1.3	where we need to comply with a legal or regulatory obligation; or</p>
                        <p>4.1.4	our legitimate interests (see Paragraph 5.2 below).</p>
                    </section>
                </div>
                <div>
                    <h6>5.	We may use your information for the following purposes: </h6>
                    <section className='pl-3'>
                        <p>5.1.1	<strong>To provide access to our Sites:</strong> to provide you with access to our Sites in a manner convenient and optimal and with personalised content relevant to you including sharing your information with our Sites’ hosts and developers (on the basis of our legitimate interest to ensure our Sites are presented in an effective and optimal manner);</p>
                        <p>5.1.2	<strong>To register your account/ create a profile:</strong> if you create an account or profile on our Sites, we will process the details provided in your registration to facilitate your account set up (on the basis of performing our contract with you);</p>
                        <p>5.1.3	<strong>To enable you to use the manuscript submission service:</strong> to enable (as applicable) the receipt, review, editing, production and publication of articles in our journals, via the manuscript submission service (on the basis of performing our contract with you);</p>
                        <p>5.1.4	<strong>To provide editorial and production services:</strong> to enable (as applicable) the receipt, review, editing, production and publication of your manuscript (or other contribution) including sharing your information with our editorial and production service providers, third-party manuscript readers (on the basis of performing our contract with you);</p>
                        <p>5.1.5	<strong>To administer your publishing contract:</strong> to enable (as applicable) us to administer your publishing contract, including payment of royalties and sharing your information with our royalty system software provider and payment service provider (on the basis of performing our contract with you);</p>
                        <p>5.1.6	<strong>Recruitment:</strong> to process any job applications you submit to us, whether directly or via an agent or recruiter including sharing this with our third-party recruitment agency (on the basis of our legitimate interest to recruit new employees or contractors);</p>
                        <p>5.1.7	<strong>Relationship management:</strong> to manage our relationship with you, which will include notifying you about changes to our terms of use or privacy policy, changes or new information about a service we provide to your such as journal subscription(s) asking you to leave a review or take a survey (on the basis of performing our contract with you, to comply with our legal obligations and on the basis of our legitimate interests to keep our records updated and study how our Sites and services are used);</p>
                        <p>5.1.8	<strong>Marketing:</strong> to keep in contact with you about our news, events, new Sites features or services or new products that we believe may interest you, provided that we have the requisite permission to do so, and sharing your information with our marketing services providers (either on the basis of your consent where we have requested it, or our legitimate interests to provide you with marketing communications where we may lawfully do so);</p>
                        <p>5.1.9	<strong>Advertising:</strong> to deliver relevant Sites content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you (on the basis of our legitimate interests in studying how our Sites/services are used, to develop them, to grow our business and to inform our marketing strategy);</p>
                        <p>5.1.10	<strong>Analytics:</strong> To use data analytics to improve our Sites, products/services, marketing, customer relationships and experiences (on the basis of our legitimate interests in defining types of customers for our Sites and services, to keep our Sites updated and relevant, to develop our business and to inform our marketing strategy);</p>
                        <p>5.1.11	<strong>User and customer support:</strong> to provide customer service and support (on the basis of our contract with you or on the basis of our legitimate interests to provide you with customer service), deal with enquiries or complaints about the Sites and share your information with our Sites developer, IT support provider, payment services provider and subscriptions system provider as necessary to provide customer support (on the basis of our legitimate interest in providing the correct products and services to our Sites users and to comply with our legal obligations);</p>
                        <p>5.1.12	<strong>Research:</strong> to carry out aggregated and anonymised research about general engagement with our Sites (on the basis of our legitimate interest in providing the right kinds of products and services to our Sites users);</p>
                        <p>5.1.13	<strong>Fraud and unlawful activity detection:</strong> to protect, investigate, and deter against fraudulent, unauthorised, or illegal activity, including without limitation plagiarism and identity fraud (on the basis of our legitimate interests to operate a safe and lawful business or where we have a legal obligation to do so);</p>
                        <p>5.1.14	<strong>Compliance with policies, procedures and laws:</strong> to enable us to comply with our policies and procedures and enforce our legal rights, or to protect the rights, property or safety of our employees and share your information with our technical and legal advisors (on the basis of our legitimate interests to operate a safe and lawful business or where we have a legal obligation to do so).</p>
                    </section>
                    <p>5.2	Where we refer to using your information on the basis of our <strong>legitimate interests</strong>, we mean our legitimate business interests in conducting and managing our business and our relationship with you, including the legitimate interest we have in personalising, enhancing, modifying or otherwise improving the services and/or communications that we provide to you.</p>
                    <p>5.3	Where we use your information for our legitimate interests, we make sure that we take into account any potential impact that such use may have on you. Our legitimate interests don’t automatically override yours and we won’t use your information if we believe your interests should override ours unless we have other grounds to do so (such as your consent or a legal obligation). If you have any concerns about our processing please refer to details in the <strong>Your Rights</strong> section below.</p>
                </div>
                <div> {/* WHO WE MIGHT SHARE YOUR INFORMATION WITH */}
                    <h6>6.	Who we might share your information with</h6>
                    <section className='pl-3'>
                        <p>6.1	We may share your personal information within our group of companies (i.e. our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006).</p>
                        <p>6.2	We may share your personal information with our suppliers, business partners, collaborators and service providers, where they are helping us to market and advertise our services as well as providing our services to you.</p>
                        <p>6.3	In connection with the purposes and on the lawful grounds described above and in addition to the recipients of your information as described above, we will share your personal information when relevant with third parties that we work with such as:</p>
                        <section className='pl-3'>
                            <p>6.3.1	<strong>Users of the Sites:</strong> Other users who you transact and communicate with via the Sites;</p>
                            <p>6.3.2	<strong>Partners and collaborators:</strong> such as the Australian Orthopaedic Association, The British Orthopaedic Association (UK), the Canadian Orthopaedic Association (Canada), the New Zealand Orthopaedic Association (New Zealand), the European Orthopaedic Research Society (Germany), the European Federation of National Associations of Orthopaedics and Traumatology, EFORT (Switzerland), the American Association of Orthopaedic Surgeons (USA);</p>
                            <p>6.3.3	<strong>Our service providers:</strong> service providers we work with to deliver our business and services;</p>
                            <p>6.3.4	<strong>Regulators and governmental bodies:</strong> HM Revenue & Customs, regulators, governmental bodies and other authorities in the United Kingdom, who require reporting of processing activities in certain circumstances;</p>
                            <p>6.3.5	<strong>Marketing parties:</strong> any selected third party that you consent to our sharing your information with for marketing purposes;</p>
                            <p>6.3.6	<strong>Prospective sellers and buyers of our business:</strong> any prospective seller or buyer of such business or assets, only in the event that we decide to sell or buy any business or assets; and</p>
                            <p>6.3.7	<strong>Other third parties (including professional advisers):</strong> any other third parties (including legal or other advisors, regulatory authorities, courts, law enforcement agencies and government agencies) based in the United Kingdom and the rest of the world where necessary to enable us to enforce our legal rights, or to protect the rights, property or safety of our employees or where such disclosure may be permitted or required by law.</p>
                        </section>
                        <p>6.4	We require third parties to maintain appropriate security to protect your information from unauthorised access or processing.</p>
                    </section>
                </div>
                <div> {/* COOKIES */}
                    <h6>7.	Cookies</h6>
                    <section className='pl-3'>
                        <p>7.1	We use cookies to ensure that you get the most out of our Sites. Cookies are small amounts of information in the form of text files which we store on the device you use to access our Sites. Cookies allow us to monitor your use of the software and simplify your use of the Sites. For example, a temporary cookie is also used to keep track of your <strong>session</strong>. Without that temporary cookie you would not be able to purchase articles via our Sites.</p>
                        <p>7.2	If you do not wish for cookies to be installed on your device, you can change the settings on your browser or device to reject cookies. For more information about how to reject cookies using your internet browser settings please consult the <strong>Help</strong> section of your internet browser (or alternatively visit http://www.aboutcookies.org.uk). Please note that, if you do set your Internet browser to reject cookies, you may not be able to access all of the functions of the Sites.</p>
                        <p>7.3	We use the following cookies: </p>
                        <section className='pl-3'>
                            <p>7.3.1	<strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.</p>
                            <p>7.3.2	<strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                            <p>7.3.3	<strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                            <p>7.3.4	<strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.</p>
                        </section>
                        <p>7.4	The names of the cookies used on our Sites and the purposes for which these cookies are used are set out in the table below:</p>
                        <table className='table table-bordered'> {/* Cookei table */}
                            <tr>
                                <th width="20%">Cookie Name; Classification</th>
                                <th width="70%">Purpose</th>
                                <th width="10%">Duration</th>
                            </tr>
                            <tr>
                                <td>JSESSIONID; Functionality</td>
                                <td>Supports functionality related to the current user's session (such as authentication status, access to licenses and access to profile).</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>MAID; Targeting</td>
                                <td>Used to target more relevant content and services to the user.</td>
                                <td>1 Year</td>
                            </tr>
                            <tr>
                                <td>PLUID; Targeting</td>
                                <td>Used to target more relevant content and services to the user.</td>
                                <td>1 Year</td>
                            </tr>
                            <tr>
                                <td>MobileUI; Functionality</td>
                                <td>This cookie is set upon arrival to the Sites using a mobile device. It is used to record the visitor's preference for using the mobile-optimized version of the Sites.</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>I2KBRCK; Functionality</td>
                                <td>Indicates whether the browser supports cookies.</td>
                                <td>1 Year</td>
                            </tr>
                            <tr>
                                <td>AUTO_SIGNIN; Strictly Necessary</td>
                                <td>Keep the current user logged on between sessions.</td>
                                <td>30 Days</td>
                            </tr>
                            {/* <tr>
                                <th colSpan={3} className='text-center'>Third-party cookies</th>
                            </tr> */}
                            <tr>
                                <td>SERVER; Analytical</td>
                                <td>Performance optimisation & currently also used to collect information on how site is used by user.</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>__utma; Analytical</td>
                                <td>Google Analytics: Used to distinguish users and sessions.</td>
                                <td>2 Years</td>
                            </tr>
                            <tr>
                                <td>__utmb; Analytical</td>
                                <td>Google Analytics: Used to determine new sessions/visits.</td>
                                <td>30 Minutes</td>
                            </tr>
                            <tr>
                                <td>__utmz; Analytical</td>
                                <td>Google Analytics: Stores the traffic source or campaign that explains how the user reached the site.</td>
                                <td>6 Months</td>
                            </tr>
                            <tr>
                                <td>ARRAffinity;  Necessary <br/><br/>ARRAffinitySameSite; Necessary</td>
                                <td>Used by a website load balancer to ensure that a user continues to communicate with the same server in the server farm. They do not track personal information.</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>ASP.NET_SessionId; Necessary</td>
                                <td>Tracks a user’s session ensuring state deliverable information. This cookie is used to track anonymous site analytics such as page hits, watch times, and more. This cookie is anonymous.</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>_cadmoreMediaLibrary; Necessary</td>
                                <td>Identifies the logged in user to maintain login state while navigating the site. For example, this is used to provide authorization, access to user created play lists, favourites, and others. This cookie contains a randomized key element and no PII and is not used for analytics.</td>
                                <td>Session</td>
                            </tr>
                            <tr>
                                <td>_ga (and variants); Analytical </td>
                                <td>Google Analytics: used for statistics to store and count page views.</td>
                                <td>2 Years</td>
                            </tr>
                        </table>
                        <p>7.5	Our Sites may contain content and links to other sites that are operated by third parties that may also operate cookies. We don’t control these third-party sites or cookies and this Privacy Notice does not apply to them. The third-party service providers that generate these cookies, such as Google and Facebook, may use their own cookies to target advertising to you on other websites, based on your visit to our Sites.</p>
                        <p>7.6	The British Editorial Society of Bone & Joint Surgery may utilize the advertising program, Google Ads, as well as re-marketing and re-targeting tracking cookies, provided by Google Inc. (<strong>Google</strong>), to help us deliver advertisements and special promotions. When you click on one of our ads, Google a remarketing cookie is installed. This cookie helps us to show our ads on Google advertisement networks. Remarketing cookies expire after 30 days and are not used to obtain personal information.</p>
                        <p>7.7	Our Sites uses Google Analytics, a service provided by Google, Inc. (<strong>Google</strong>) to help us understand how visitors use the site. The information generated by the cookie will be stored by Google and will be used for the purpose of evaluating your use of our Sites and compiling reports on Sites activity. The information is anonymous and only used for statistical purposes, and it helps us to develop a better user experience.</p>
                        <p>7.8	You can see how these platforms are using cookies and how to disable them here: <a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a></p>
                    </section>
                </div>
                <div> {/* HOW WE LOOK AFTER YOUR INFORMATION AND HOW LONG WE KEEP IT FOR */}
                    <h6>8.	How we look after your information and how long we keep it for</h6>
                    <section className='pl-3'>
                        <p>8.1	We operate a policy of privacy by design by looking for opportunities to minimise the amount of personal information we hold about you. We use appropriate technological and operational security measures to protect your information against any unauthorised access or unlawful use, such as:</p>
                        <section className='pl-3'>
                            <p>8.1.1	ensuring the physical security of our offices, warehouses or other sites;</p>
                            <p>8.1.2	ensuring the physical and digital security of our equipment and devices by using appropriate password protection and encryption;</p>
                            <p>8.1.3	maintaining a data protection policy for, and delivering data protection training to, our employees;</p>
                            <p>8.1.4	limiting access to your personal information to those in our company who need to use it in the course of their work.</p>
                        </section>
                        <p>8.2	We will retain your information for as long as is necessary to provide you with the services that you have requested from us or for as long as we reasonably require to retain the information for our lawful business purposes, such as for the purposes of exercising our legal rights or where we are permitted to do so for purposes of academic, literary expression and research purposes. We operate a data retention policy and look to find ways to reduce the amount of information we hold about you and the length of time that we need to keep it. For example,</p>
                        <section className='pl-3'>
                            <p>8.2.1	we archive our email and paper correspondence regularly and destroy information older than 6 years. If in any circumstance correspondence needs to be kept after 6 years, it will be stored securely at The British Editorial Society of Bone & Joint Surgery;</p>
                            <p>8.2.2	we retain information relating to orders, refunds and customer queries for the current and previous year securely at The British Editorial Society of Bone & Joint Surgery. They are then permanently destroyed 2 years after. Payment information is destroyed immediately;</p>
                            <p>8.2.3	we destroy unpublished manuscripts and related correspondence after 10 years however we may permanently keep a log recording brief details of these in the event of a legal claim or for Copyright purposes;</p>
                            <p>8.2.4	we maintain a suppression list of email addresses of individuals who no longer wish to be contacted by us. So that we can comply with their wishes we must store this information permanently.</p>
                        </section>
                    </section>
                </div>
                <div> {/* INTERNATIONAL TRANSFERS OF YOUR INFORMATION */}
                    <h6>9.	International transfers of your information</h6>
                    <section className='pl-3'>
                        <p>9.1	Our company is located in the UK.</p>
                        <p>9.2	Many of our external third-party service providers are based outside the UK and/or European Economic Area (EEA) so their processing of your personal information will involve a transfer of information outside the UK and/or EEA to countries which may have less strict data protection laws.</p>
                        <p>9.3	Whenever we transfer your personal information to countries that are outside of the UK/EEA, we will take steps which are reasonably necessary to ensure that adequate safeguards are in place to protect your personal information and to make sure it is treated securely and in accordance with this Privacy Notice by using at least one of the following transfer solutions: </p>
                        <section className='pl-3'>
                            <p>9.3.1	we will only transfer your personal information to countries that have been deemed to provide an adequate level of protection for personal data by the Information Commissioner’s office or the European Commission;</p>
                            <p>9.3.2	we rely on approved contractual data transfer mechanisms (such as the EU “Standard Contractual Clauses”) to ensure your information is subject to adequate safeguards in the recipient country.</p>
                        </section>
                        <p>9.4	If you are located in the UK/EEA, you may contact us for further details of the safeguards which we have put in place to protect your personal information and privacy rights in these circumstances. Please contact us using the contact details at the top of this Privacy Notice if you would like further information.</p>
                    </section>
                </div>
                <div> {/* YOUR RIGHTS */}
                    <h6>10. Your rights</h6>
                    <section className='pl-3'>
                        <p>10.1	You have certain rights in respect of the information that we hold about you, including: </p>
                        <section className='pl-3'>
                            <p>10.1.1	the right to be informed of the ways in which we use your information, as we seek to do in this Privacy Notice;</p>
                            <p>10.1.2	the right to ask us not to process your personal data for marketing purposes;</p>
                            <p>10.1.3	the right to request access to the information that we hold about you;</p>
                            <p>10.1.4	the right to request that we correct or rectify any information that we hold about you which is out of date or incorrect;</p>
                            <p>10.1.5	the right to withdraw your consent for our use of your information in reliance of your consent (refer to Paragraph 5 to see when we are relying on your consent), which you can do by contacting us using any of the details at the top of this Privacy Notice;</p>
                            <p>10.1.6	the right to object to our using your information on the basis of our legitimate interests (refer to Paragraph 5 above to see when we are relying on our legitimate interests) (or those of a third party) and there is something about your particular situation which makes you want to object to our processing on this ground;</p>
                            <p>10.1.7	the right to receive a copy of any information we hold about you (or request that we transfer this to another service provider) in a structured, commonly-used, machine readable format, in certain circumstances;</p>
                            <p>10.1.8	in certain circumstances, the right to ask us to limit or cease processing or erase information we hold about you;</p>
                            <p>10.1.9	the right to lodge a complaint about us to the UK Information Commissioner’s Office (<a href='https://ico.org.uk/' target='_blank'>https://ico.org.uk/</a>) as well as a right to lodge a complaint with the relevant authority in your country of work or residence; and</p>
                            <p>10.1.10	Please note that we may need to retain certain information for our own record-keeping and research purposes even if you have asked us to stop processing it. We may also need to send you service-related communications relating to your user account even when you have requested not to receive marketing communications.</p>
                        </section>
                        <h6>How to exercise your rights</h6>
                        <p>10.2	You may exercise your rights above by contacting us using the contact details set out at the top of this Privacy Notice, or in the case of preventing processing for marketing activities also by checking certain boxes on forms that we use to collect your data to tell us that you don’t want to be involved in marketing or by updating your marketing preferences via your account with us. We will handle any request to exercise your rights in accordance with applicable law and any relevant legal exemptions.</p>
                    </section>
                </div>
                <div> {/* Sharing data directly with Third Parties */}
                    <h6>11.	Sharing data directly with Third Parties</h6>
                    <section className='pl-3'>
                        <p>11.1	You might end up providing personal information directly to third parties as a consequence of your interactions with our Sites and other services offered by us. For example, your name and other personal information will be shared with other users when you correspond with them via the Sites, or you may attend an event hosted by us where you communicate personal information directly with other attendees. We are not responsible for how such third parties use personal data provided by you.</p>
                        <p>11.2	Please be responsible with personal information of others when using our Sites and the services available on it. We are not responsible for your misuse of personal information, or for the direct relationship between you and others when takes place outside of the Sites or our services.</p>
                    </section>
                </div>
                <div> {/* Third-party links */}
                    <h6>12.	Third-party links</h6>
                    <section className='pl-3'>
                        <p>12.1	The Sites may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy practices. When you leave our Sites, we encourage you to read the privacy notice of every one of the Sites you visit.</p>
                    </section>
                </div>
                <div> {/* Changes to this Privacy Notice */}
                    <h6>13. Changes to this Privacy Notice</h6>
                    <section className='pl-3'>
                        <p>13.1	We may make changes to this Privacy Notice from time to time. We will post any changes to our site, or notify you of any material changes by e-mail.</p>
                    </section>
                    <p className='pt-3 mt-3'><strong>This Privacy Notice was updated on 29 July 2024.</strong></p>
                </div>
            </Col>
        </Row>
    )
}

export default PrivacyPolicy;