import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tab, InputGroup, FormControl, Row, Col, Card, Spinner, Tooltip } from 'react-bootstrap';
import SliderBar from '../../components/SliderBar/SliderBar';
import JournalInfo from '../../components/Journals/JournalInfo';
import classNames from 'classnames';
import styles from './JournalSearch.module.scss';
import Facets from '../../components/Facets/Facets';
import { toast } from 'react-toastify';
import { dataDisplayRequest } from '../../utils/auth_service/AuthServiceHelper';
import ArticleList from '../../components/Articles/ArticleList';
import Pagination from '../../components/PaginationComponent/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faInfoCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import history from '../../history';
import ConfirmationModal from '../Menuscript/ConfirmationModal/ConfirmationModal';
import DuplicateCheckModal from '../Menuscript/DuplicateCheckModal/DuplicateCheckModal';
import AxiosService from '../../utils/AxiosService';
import { FieldLoader, FullLoader, ProgressLoader } from '../../components/Loader/CommonLoader';
import CustomeAutoComplete from '../../components/CustomeAutoComplete/CustomeAutoComplete';
import TooltipButton from '../../components/TooltipButton/TooltipButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import EditingSuggestion from '../../components/TooltipButton/EditingSuggestion';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage';
import PaymentModal from '../Menuscript/PaymentModal/PaymentModal';


function JournalSearch(){

	const [pageLoad, setPageLoad] = useState(false);
	const [loader, setLoader] = useState(false);
	const [isImpactFactorLoading, setIsImpactFactorLoading] = useState(false);

	const [btnLoader, setButtonLoader] = useState({isDraftBtn: false, isCreateBtn: false, isClone:false, isDefault:false})
	const [show, setShow] = useState({isduplicateCheck:false, isConfirmationCheck: false, isPaymentCheck: false});
	const [force, setForce]= useState(false);
	const apiService = new AxiosService()
	const [manuscriptDetails, setManuscriptDetails] = useState([]);
	const [activeJournal, setActiveJournal] = useState({journalTitle: "", journalId: "", genericTemplate: false});

	// const [textKeywords, setTextKeywords] = useState({journalKeyword:"", articleKeyword:""})
	const [journalSearchFormValues, setJournalSearchFormValues] = useState({journal_title: '', keywords: "", impact_factor: [0,91], published_from: '', published_to: '', templateType: 0});
	const [litSearchFormValues, setLitSearchFormValues] = useState({title: '', abstract: '', text: ''})
	const [litSearchFormErrors, setLitSearchFormErrors] = useState({title: '', abstract: '', freeText: ''})
	const [bibliographyFormValues, setBibliographyFormValues] = useState({article_title: '', authorName: '', keywords: '', institutionName: '', pmid: ''})
	const [bibliographyFormErrors, setBibliographyFormErrors] = useState({authorName: '', keywords: '', institutionName: '', pmid: '', articleTitle:''})
	
	const [citationDomain, setCitationDomain] = useState([0, 200]);
	const [journalList, setJournalList] = useState([]);
	const [similarArticles, setSimilarArticles] = useState([])
	const [articleList, setArticleList] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [accessTypeFacets, setAccessTypeFacets] = useState([]);
	const [resultsNotFound, setResultsNotFound] = useState("");
	const [biblioResultsNotFound, setBiblioResultsNotFound] = useState("");
	const [facetsRefresh, setFacetsRefresh] = useState(false);
	const [display, setDisplay] = useState(false);
	const [journalListOption, setJournalListOption] = useState([]);
    const [loading, setLoading] = useState(false)


	const queryParams = new URLSearchParams(window.location.search);
	const form = queryParams.get('form') ? JSON.parse(queryParams.get('form')) : {};
	const currentPage = queryParams.get('from') ? parseInt(queryParams.get('from')) : 1;
	const offset = queryParams.get('offset') ? queryParams.get('offset') : 10;
	const type = queryParams.get('type') ? queryParams.get('type') : 'field';
	const appliedFacets = queryParams.get('facets') ? JSON.parse(queryParams.get('facets')) : [];
	const sortType = queryParams.get('sort') ? queryParams.get('sort') : "";
	const [activeTab, setActiveTab] = useState((type === 'field' || type === 'text') ? 'similarArticles' : (type === 'journal' ? 'findJournal' : 'bibliography'))
	const selectedSearch = type === 'text' ? 'freeText' : 'titleAbstract';
	const sortBy = 'asc';
	const [pageNo, setPageNo] = useState(currentPage)
	const [totalPage, setTotalPage] = useState(0)
	const [sort, setSort] = useState(queryParams.get('sort') ? queryParams.get('sort') : "")
	const [didYouMeanList, setDidYouMeanList] = useState([])
	const [languageError, setLanguageError] = useState("")
	const localStorageService = LocalStorageService.getService()
	const authDetails = JSON.parse(localStorageService.getAuthDetails()) 
	const tabRef = useRef()

	function getCitationScoreRange(range){
		setJournalSearchFormValues({...journalSearchFormValues, impact_factor: range})
	}

	function handleDidYouMeanSearch(e, word){
		if(type==="field"){
			setLitSearchFormValues({...litSearchFormValues, title: word})
			let localForm = {
				title: word,
				abstract: litSearchFormValues.abstract.trim()
			}
			handleDidYouMean(word)
			history.push("/search?type=field" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(localForm)))	
		}else if(type === "journal"){
			let localForm ={}
			if(form?.keywords){
				let splitKeyword= word?.trim().split(" ");
				let localKeyword = splitKeyword.map(keyword=> keyword?.trim())
				setJournalSearchFormValues({...journalSearchFormValues, keywords: localKeyword?.filter(key=> key!=="")})
				if(form?.journal_title){
					localForm["journal_title"]= form.journal_title
				}
				if(form?.keywords){
					localForm["keywords"]= localKeyword?.filter(key=> key!=="")
				}
				if(form?.impact_factor){
					localForm["impact_factor"]= form.impact_factor
				}
			}
			
			handleDidYouMean(word)
			history.push("/search?type=journal" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(localForm)))

		}else {
			let localForm ={}
			if(form?.keywords){
				let splitKeyword= word?.trim().split(" ");
				let localKeyword = splitKeyword.map(keyword=> keyword?.trim())
				setBibliographyFormValues({...bibliographyFormValues, keywords: localKeyword?.filter(key=> key!=="")})
				if(form?.article_title){
					localForm["article_title"]= form.article_title
				}
				if(form?.keywords){
					localForm["keywords"]= splitKeyword
				}
				if(form?.doi){
					localForm["doi"]= form.doi
				}
				if(form?.institute){
					localForm["institute"] = form.institute
				}
				if(form?.authors){
					localForm["authors"]= form.authors
				}
				
			}
			
			handleDidYouMean(word)
			history.push("/search?type=bibliography" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(localForm))+ "&sort=" + sortType)
		}		
	}

	function handleDidYouMean(keyword) {
		if(typeof keyword === "string") {
			if(keyword?.trim()){
				apiService.getDidYouMean({"query": keyword}).then(res=> {
					setDidYouMeanList(res);
				}).catch(err=> {
					toast.error(err.response.data.message,  {
						autoClose: 3000,
					})
				})
			}
		} else {
			apiService.getDidYouMean({"query": keyword.join(",")}).then(res=> {
				setDidYouMeanList(res);
			}).catch(err=> {
				toast.error(err.response.data.message,  {
					autoClose: 3000,
				})
			})
		}
	}

	function getJournalSearch(event){
		event.preventDefault()
		if(activeTab === 'similarArticles'){
			// setArticleList([])
			if(selectedSearch === 'titleAbstract'){
				let trimedTitle = litSearchFormValues?.title?.trim()
				let titleLenght = trimedTitle.split(" ").filter(v=> v!=="").length
				let trimedAbstract = litSearchFormValues?.abstract?.trim()
				let abstractLenght = trimedAbstract.split(" ").filter(v=> v!=="").length

				if(titleLenght >2 && abstractLenght>2){
					setLitSearchFormErrors({...litSearchFormErrors, freeText: "", title:"", abstract:''})
					setLitSearchFormValues({...litSearchFormValues, text:""})
					let data = {};
					data["title"] = trimedTitle;
					data["abstract"] = trimedAbstract;
					if(appliedFacets.length) {
						data["acess_type"] = appliedFacets; 
					}
					setPageNo(1)
					history.push("/search?type=field" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data)))	
				} else {
					setLitSearchFormErrors({
						...litSearchFormErrors,
						title: trimedTitle?.length ===0 ?"* Title is required": titleLenght>0 && titleLenght<3? "Title must have min. 3 words": "",
						abstract: trimedAbstract?.length ===0 ?"* Abstract is required": abstractLenght>0 && abstractLenght<3? "Abstract must have min. 3 words": "" 

					})

					toast.error('Please fill in the required fields.', {autoClose: 3000});
				}
			}
			
		} else if(activeTab === 'bibliography'){
			let trimedArticleTitle = bibliographyFormValues?.article_title?.trim()
			if(trimedArticleTitle || bibliographyFormValues?.authorName?.trim() ||bibliographyFormValues?.institutionName?.trim() || bibliographyFormValues?.pmid?.trim() || bibliographyFormValues?.keywords?.trim()) {
				let data = {};
				if(bibliographyFormValues?.keywords && typeof(bibliographyFormValues?.keywords)==="string"){
					let splitKeyword= bibliographyFormValues?.keywords?.trim().split(", ");
					let localKeyword = splitKeyword.map(keyword=>  {
						if (typeof keyword === 'string' && keyword !=="") {
						return keyword?.trim();
						}else return keyword
					})
					data["keywords"] = localKeyword?.filter(key=> key!=="")
					handleDidYouMean(bibliographyFormValues.keywords)
				}
				if(bibliographyFormValues.article_title){
					data["article_title"] = bibliographyFormValues?.article_title?.trim()
				}
				if(bibliographyFormValues.authorName){
					data["authors"] = [bibliographyFormValues?.authorName?.trim()]
				}
				if(bibliographyFormValues.pmid){
					data["doi"] = bibliographyFormValues?.pmid?.trim()
				}
				if(bibliographyFormValues.institutionName){
					data["institute"] = bibliographyFormValues?.institutionName?.trim()
				}
				setPageNo(1)
				if(data?.authors?.length > 0 && !data?.article_title && !data?.doi && !data?.institute && !data?.keywords) {
					history.push("/search?type=bibliography" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=pubdate`)
				} else if (data?.institute?.length > 0 && !data?.article_title && !data?.doi && !data?.authors && !data?.keywords) {
					history.push("/search?type=bibliography" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=pubdate`)
				} else if (bibliographyFormValues.keywords) {
					history.push("/search?type=bibliography" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=`)
				} else {
					history.push("/search?type=bibliography" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=`)
				}

			}
			else {
				toast.error("Please fill in the required fields.", {autoClose: 3000})
			}
		} else {
			setArticleList([])
			let data = {}
			if(journalSearchFormValues?.keywords && typeof(journalSearchFormValues?.keywords) ==="string"){
				let splitKeyword= journalSearchFormValues?.keywords?.trim().split(",");
				let localKeyword = splitKeyword.map(keyword=>  {
					if (typeof keyword === 'string' && keyword !=="") {
						return keyword?.trim();
					} else {
						return keyword
					}
				})
				data["keywords"] = localKeyword?.filter(key=> key!=="")
				handleDidYouMean(journalSearchFormValues.keywords)
			} else {
				if(journalSearchFormValues?.keywords?.length) {
					data["keywords"] = journalSearchFormValues?.keywords?.filter(key => key !== "")
					handleDidYouMean(journalSearchFormValues.keywords)
				}
			}
	
			if(journalSearchFormValues.journal_title){
				data["journal_title"] = journalSearchFormValues?.journal_title?.trim()
			}
			if(journalSearchFormValues.published_from && journalSearchFormValues.published_to){
				data["publication_year"] = [journalSearchFormValues.published_from, journalSearchFormValues.published_to]
			}
			data["impact_factor"] = journalSearchFormValues.impact_factor;
			if(appliedFacets.length) {
				data["acess_type"] = appliedFacets; 
			}
			if(journalSearchFormValues.templateType === 1) {
				data["formatting"] = "1"
			}
			setPageNo(1)
			if(journalSearchFormValues?.journal_title || journalSearchFormValues?.keywords){
				if(sort.length < 1 || sort === "Rank") {
					history.push("/search?type=journal" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=Rank`)					
				} else {
					history.push("/search?type=journal" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=`)
				}
			}else {
				if(sort.length < 1 || sort === "Rank") {
					history.push("/search?type=journal" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=Rank`)
				} else {
					history.push("/search?type=journal" + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(data))+ `&sort=`)
				}
			}
		} 
		setFacetsRefresh(false);
	}

	function addKeyword(value) {
		if(value.trim() === ''){
			return;
		}
		else{
			const localKeywords = journalSearchFormValues.keywords.slice();
			localKeywords.push(value);
			setJournalSearchFormValues({...journalSearchFormValues, keywords: localKeywords})   
		}
	};

	function handleBibliographyAddKeyword(event) {
		setBibliographyFormValues({...bibliographyFormValues, keywords: event.target.value})
	}
	function handleJournalAddKeyword(event) {
		// setTextKeywords({...textKeywords, journalKeyword: event.target.value})
	}
	  
	function removeKeyword(index) {
		const localKeywords = journalSearchFormValues.keywords.slice();
		localKeywords.splice(index, 1);
		setJournalSearchFormValues({...journalSearchFormValues, keywords: localKeywords})
	};

	function addBibliographyKeyword(value) {
		if(value.trim() === ''){
			return;
		}
		else{
			const localKeywords = bibliographyFormValues.keywords.slice();
			localKeywords.push(value);
			setBibliographyFormValues({...bibliographyFormValues, keywords: localKeywords})
		}
	};
	  
	function removeBibliographyKeyword(index) {
		const localKeywords = bibliographyFormValues.keywords.slice();
		localKeywords.splice(index, 1);
		setBibliographyFormValues({...bibliographyFormValues, keywords: localKeywords})
	};

	function handleSearchFormChange($event){
		$event.preventDefault();
		const { name, value } = $event.target;
		setJournalSearchFormValues({ ...journalSearchFormValues, [name]: value });
	}

	function handleLitFormChange($event){
		$event.preventDefault();
		const { name, value } = $event.target;
		setLitSearchFormValues({...litSearchFormValues, [name]: value})
	}

	function handleBibliographyFormChange($event){
		$event.preventDefault();
		const { name, value } = $event.target;
		setBibliographyFormValues({...bibliographyFormValues, [name]: value})
	}

	function handleValidation($event){
		const { name, value } = $event.target;
		let lenght = value?.trim()?.split(" ").filter(v=> v!=="").length
		switch(name){
			case "article_title":
				setBibliographyFormErrors({
					...bibliographyFormErrors,
					articleTitle:
					value.length > 0 && lenght<3  ? "Article title must have min. 3 words": ""
				});
				break;
			default:
				break;
		}
	}

	function handleLitFormValidation($event){
		const { name, value } = $event.target;
		let length = value?.trim()?.split(" ").filter(v=> v!=="").length
		switch(name){
			case "title":
				setLitSearchFormErrors({
					...litSearchFormErrors,
					title:
						value.length === 0 ? "* Title is required": length > 0 && length<3? "Title must have min. 3 words" : "" 
				});
				break;
			case 'abstract':
				
				setLitSearchFormErrors({
					...litSearchFormErrors,
					abstract:
					value.length === 0 ? "* Abstract is required": length > 0 && length<3  ? "Abstract must have min. 3 words": ""
				});
				break;
			default:
				break;
		}
	}

	function handleSort(event){
		setPageNo(1)
		setSort(event.target.value)
		history.push("/search?type=" + type + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(form)) + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets)) + "&sort=" + event.target.value)
	}

	function changeActiveTab(tab){
		const localType = tab === 'similarArticles' ? 'field' : (tab === 'findJournal' ? 'journal' : 'bibliography')
		history.push("/search?type=" + localType)
		setArticleList([])
		setJournalList([])
		setResultsNotFound('')
		setBiblioResultsNotFound('')
		if(type==="journal"){
			if (localType === "journal") {
				setSort("Rank")
			} else {
				setSort("publication_year")
			}
		}else{
			if (localType === "journal") {
				setSort("Rank")
			} else {
				setSort("pubdate")
			}
		}
		if(localType==="field"){
			setLitSearchFormErrors({...litSearchFormErrors, title: "",abstract:""})
			setLitSearchFormValues({...litSearchFormValues, title: "", abstract:""})
		}else if(localType==="journal"){
			setJournalSearchFormValues({...journalSearchFormValues ,journal_title: '', keywords: "", impact_factor: [0,200], published_from: '', published_to: '', templateType: 0});	
		}else{
			setBibliographyFormValues({...bibliographyFormValues ,article_title: '', authorName: '', keywords: '', institutionName: '', pmid: ''})
		}
		if(tab === "similarArticles") {
			sideScroll(tabRef.current, 5, 80, 10)
		} else if (tab === "findJournal") {
			sideScroll(tabRef.current, 5, 80, -10)
		} else if (tab === "bibliography") {
			sideScroll(tabRef.current, 5, 80, 10)
		}
	}
	
	function changeSelectedSearch(search){
		const localType = search === 'freeText' ? 'text' : 'field'; 
		history.push("/search?type=" + localType + "&from=" + currentPage + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(form)))
	}

	function setCurrentPage(page){
		setPageNo(page)
		history.push("/search?type=" + type + "&from=" + page + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(form)) + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets)) + "&sort=" + sortType);
	}

	function getAppliedFilters(getAppliedFacets){
		let localForm = {...form, "acess_type": getAppliedFacets}
		setPageNo(1)
		history.push("/search?type=" + type + "&from=" + 1 + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(localForm)) + "&facets=" + encodeURIComponent(JSON.stringify(getAppliedFacets))+"&sort=" + sortType)
	}

	function resetSearch(){
		history.push("/search?type=" + type);
		setSort("")
		window.location.reload();
	}

	function getIFRange(){
		setIsImpactFactorLoading(true)
		const config = {
			method: "get",
			url: '/api/v1/serch-service/impactFactorRange',
		}
		dataDisplayRequest(config).then(response => {
			setIsImpactFactorLoading(false)
			// setCitationDomain([Math.ceil(response.impact_factor.min_if), Math.ceil(response.impact_factor.max_if)])
			setCitationDomain([Math.ceil(response.impact_factor.min_if), 200])
			if(activeTab !== 'journal' && !Object.keys(form).length){
				setJournalSearchFormValues({...journalSearchFormValues, impact_factor: [Math.ceil(response.impact_factor.min_if), Math.ceil(response.impact_factor.max_if)]})
				setJournalSearchFormValues({...journalSearchFormValues, impact_factor: [Math.ceil(response.impact_factor.min_if), 200]})
			}
		}, error => {
			setIsImpactFactorLoading(false)
			toast.error(error.response.data.message,  {
				autoClose: 3000,
			});
		})
	}

	function getJournal(config) {
		dataDisplayRequest(config).then(response => {
			setLoader(false);
			setJournalList(response.data);
			setTotalRecords(response.total_count);
			setTotalPage(Math.ceil(response.total_count / offset))
			setAccessTypeFacets(response.acess_type);
			response.data.length ? setResultsNotFound("") : setResultsNotFound("Your search did not match any content")
			setArticleList([]);
			setBiblioResultsNotFound("");
			window.scrollTo({top: 600, behavior: 'smooth'});
		}, error => {
			setLoader(false);
			setResultsNotFound("Something went wrong!")
			toast.error(error?.response?.data?.error,  {
				autoClose: 3000,
			});    
			setJournalList([]);   
			setArticleList([]);
			setBiblioResultsNotFound("");
			window.scrollTo({top: 600, behavior: 'smooth'});
		})
	}

	function HandleIrregularInputSearch(config){
		apiService.checkIrregularInputSearch({
			"search_query": form.title
		  }).then(response => {
			if(response?.predicted_label === "Regular"){
				setPageNo(currentPage)
				getJournal(config)
			}else {
				setLoader(false);
				setJournalList([]);   
				window.scrollTo({top: 600, behavior: 'smooth'});
				setResultsNotFound("Your search did not match any content")
			}
		  }).catch(err=> {
			toast.error(err.response.data.message,  {
				autoClose: 3000,
			})
		  })
	}

	const getLanguage = (languages) => {
		let language = languages.slice(0, 5).filter((item) => {
			// console.log("Item in loop: ", item)
			if(item.languageName === "English" && item.language === "eng") {
				// console.log("Item in true condition: ", item)
				return item
			}
		})
		// console.log("language array: ", language)
		if(language[0]?.language) {
			return language[0]
		} else {
			return {language: ""}
		}
	}

	const  handleLanguageDetection = async (config) => {
		const titleLang = await apiService.languageDetection({
			"text": form.title
		}).then(res=>getLanguage(res))

		const abstractLang = form.abstract ? await apiService.languageDetection({
			"text": form.abstract
		}).then(res=>getLanguage(res)): "eng"

		if(titleLang.language ==="eng" && (abstractLang.language ==="eng" || abstractLang ==="eng")){			
			handleDidYouMean(form?.title)
			HandleIrregularInputSearch(config)
		}else {
			setLoader(false);
			setJournalList([]);   
			window.scrollTo({top: 600, behavior: 'smooth'});
			setDidYouMeanList([])
			setResultsNotFound("Invalid language is detected!")
			setLanguageError("Only English language search is allowed.")
		}
	}

	function findJournal(){
		const start = (currentPage - 1) * offset;
		setSort(sortType)
		const config = {
			method: "post",
			url: "/api/v1/serch-service/search/" + type + "?from=" + start + "&offset=" + offset + "&operator=and",
			data: {...form, 'sort': sortType ==="pubdate" || sortType==="publication_year" || sortType === "Rank"? "desc":sortBy, 'field': sortType }
		}
		if (sortType === "journal_title_asc") {
			config.data = {...form, 'sort': 'asc', 'field': 'journal_title'}
		} else if (sortType === 'journal_title_desc') {
			config.data = {...form, 'sort': 'desc', 'field': 'journal_title'}
		} else if (sortType === "impact_factor_asc") {
			config.data = {...form, 'sort': 'asc', 'field': 'impact_factor'}
		} else if (sortType === "impact_factor_desc") {
			config.data = {...form, 'sort': 'desc', 'field': 'impact_factor'}
		}
		// else if (sortType === "article_title_asc") {
		// 	config.data = {...form, 'sort': 'asc', 'field': 'article_title'}
		// } else if (sortType === "article_title_desc") {
		// 	config.data = {...form, 'sort': 'desc', 'field': 'article_title'}
		// }
		setLoader(true);
		if(type === 'field'){
			handleLanguageDetection(config)
		}else if(type === 'journal'){
			setPageNo(currentPage)
			getJournal(config)
		}
		else{
			setPageNo(currentPage)
			dataDisplayRequest(config).then(response => {
				setLoader(false);
				setArticleList(response.data);
				setTotalRecords(response.total_count);
				setTotalPage(Math.ceil(response.total_count / offset))
				response.length ? setBiblioResultsNotFound("") : setBiblioResultsNotFound("Your search did not match any content")
				setResultsNotFound("");
				setJournalList([]);
				window.scrollTo({top: 600, behavior: 'smooth'});
			}, error => {
				setLoader(false);
				setArticleList([]);
				setBiblioResultsNotFound("Something went wrong!")
				toast.error(error?.response?.data?.error,  {
                    autoClose: 3000,
                });
				setResultsNotFound("");
				setJournalList([]);
				window.scrollTo({top: 600, behavior: 'smooth'});
			})
		}
	}

	const getManuscriptData = (lexiconId, force, genericTemplate) => {
		const body = {
				"journalId": lexiconId,
				"force": force
		}
		if(genericTemplate) {
			body.templateType = "Generic"
		}
		apiService.createManuscript(body).then(res => {
			if(genericTemplate) {
				apiService.getStripeUrl(res.manuscriptId, activeJournal.journalTitle).then(res => {
					setButtonLoader({...btnLoader, isCreateBtn: false, isDefault: false});
					setShow({...show, isPaymentCheck: false});
					window.location.replace(res.sessionURL)
				}, err => {
					console.log("Error in generating stripe Url: ", err)
					setButtonLoader({...btnLoader, isCreateBtn: false, isDefault: false});
					setShow({...show, isConfirmationCheck: false});
				})
			} else {
				setButtonLoader({...btnLoader, isCreateBtn: false, isDefault: false});
				toast.success("Created successfully.",  {
					autoClose: 3000,
				});
				history.push(`/manuscript-submission/${res.manuscriptId}`);
			}
		}).catch(e=> {
			setLoader({...loader, isCreateBtn: false, isDefault: false})
			if(e.response.status === 409) {
				setManuscriptDetails(e.response.data)
				setShow({...show, isduplicateCheck: true});
			}else{
				toast.error(e.response.data.message,  {
                    autoClose: 3000,
                })
			}
		});
	}

	const handleConfirmationModal = (journal) => {
		if( journal.genericTemplate ) {
			setActiveJournal({journalTitle: journal.journal_title, journalId: journal.lexicon_id, genericTemplate: true})
			setShow({...show, isPaymentCheck: true});
		} else {
			setActiveJournal({journalTitle: journal.journal_title, journalId: journal.lexicon_id})
			setShow({...show, isConfirmationCheck: true});
		}
	}

	const createDefaultManuscript = () => {
		setForce(false);
		setButtonLoader({...btnLoader, isDefault:true});
		if(activeJournal.genericTemplate) {
			getManuscriptData(activeJournal.journalId, false, activeJournal.genericTemplate);	
		} else {
			getManuscriptData(activeJournal.journalId, false);
		}
	}

	function handleArticleKeyword(event) {
		if(event.keyCode===13){
			getJournalSearch(event)
		}
	}


	useEffect(() => { 
		setPageLoad(true);
		getIFRange();
		if(Object.keys(form).length){
			console.log("form found")
			if(type === 'field' || type === 'text'){
				setLitSearchFormValues(form)
				handleDidYouMean(form?.title)
			}
			else if(type === 'journal'){
				let word = form?.keywords
				if(form.keywords && typeof(form.keywords)==="string"){
					handleDidYouMean(word)
					let newForm = {...form, keywords: form.keywords.split(" ").map(word=> word)}			
					setJournalSearchFormValues(newForm)

				}else{
					setJournalSearchFormValues(form)
					if(form?.keywords){
						let local = form?.keywords.map(word=> word).join(", ")
						handleDidYouMean(local)
					}
				}
			} else{
				let word = form?.keywords
				if(form.keywords && typeof(form.keywords)==="string"){
					handleDidYouMean(word)
					let newForm = {...form, keywords: form.keywords.split(" ").map(word=> word)}			
					setBibliographyFormValues(newForm)

				}else{
					setBibliographyFormValues(form)
					if(form?.keywords){
						let local = form?.keywords.map(word=> word).join(", ")
						handleDidYouMean(local)
					}
				}
				
			}
		} else {
			setDidYouMeanList([])
		}
	}, [type])

	
	function handleGoToPagination() {
		let totalPage= Math.ceil(totalRecords / offset)
		if(pageNo>0 && pageNo<=totalPage){
		history.push("/search?type=" + type + "&from=" + pageNo + "&offset=" + offset + "&operator=and&form=" + encodeURIComponent(JSON.stringify(form)) + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets)) + "&sort=" + sort);
		}
		else {
			toast.error("Please enter valid page number!", {autoClose: 3000})
		}
	}

	// custome auto select functions

	const handleSelect = (selectedValue) => {
		setJournalSearchFormValues({...journalSearchFormValues, journal_title: selectedValue})
	}

	function getJournalName(searchValue) {
		setLoading(true);
		const body = {
			  "field": "journalTitle",
			  "value": searchValue
		  }
		apiService.getJournalNameOptions(body).then(res=> {
		  setLoading(false)
		  setJournalListOption(res.result)
		}).catch(err=> {
		  setLoading(false)
		  toast.error(err.response.data.message, {autoClose: 3000})
		})
	  }
  
	  const getSuggestion= () => {
		// let obj =[]
		// obj =  window.WEBSPELLCHECKER.getInstances()
		// if(obj[0]){
		//   obj[0].openDialog()
		// }else {
		//   toast.error(`Please select text area!`, {
		// 	position: toast.POSITION.TOP_CENTER,
		// 	autoClose: 3000
		//   });
		// }
	   }

	const handleTemplateCheckboxChange = (e) => {
		if(e.target.checked) {
			setJournalSearchFormValues({...journalSearchFormValues, templateType: 1})
		} else {
			setJournalSearchFormValues({...journalSearchFormValues, templateType: 0})
		}
	}

	const sideScroll = (element, speed, distance, step) => {
		let tabContainer = element.children[0]
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            tabContainer.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    };

	const handleKeyPressed13= (e) => {
		if(e.keyCode===13) {
			e.preventDefault();
			getJournalSearch(e)
		}
	}

	useEffect(() => {
		if(pageLoad){
			// setAppliedFacets([]);
			setFacetsRefresh(true);
		}
	}, [selectedSearch, activeTab])
 
	useEffect(() => {
		if(Object.keys(form).length){
			findJournal();
		}
	}, [JSON.stringify(form), currentPage, JSON.stringify(appliedFacets), sortType])
  
	// useEffect(()=>{			
	// 	if(activeTab==="similarArticles"){
	// 	window.WEBSPELLCHECKER.init({container: document.querySelector('.textarea-element')});

	// 	}else if(activeTab==="findJournal"){
	// 		window.WEBSPELLCHECKER.init({container: document.querySelector('#journal-textarea-element')});
	// 	}else {
	// 		window.WEBSPELLCHECKER.init({container: document.querySelector('#article-textarea-element')});
	// 	}	
	// },[activeTab])

	useEffect(() => {
		if(type === 'field' || type === 'text') {
			setActiveTab("similarArticles")
		} else if (type === "journal") {
			setActiveTab('findJournal')
		} else {
			setActiveTab('bibliography')
		}
	}, [type])

	useEffect(() => {
		if (form?.formatting === 1) {
			setJournalSearchFormValues({...journalSearchFormValues, templateType: 1})
		}
	},[form])

	useEffect(() => {
		setTimeout(() => {
			sideScroll(tabRef.current, 5, 80, 10)
			setTimeout(() => {
				sideScroll(tabRef.current, 5, 80, -10)
			}, 300)
		}, 500)
	}, [])
	  
	return (
		<div className='mt-3'>
			
			<Card>
				<Card.Body>
					{/* <form onSubmit={(event) => getJournalSearch(event)}> */}
					<div className='mb-3'>
						<h4>Find a journal</h4>
						<p className={styles.infoLabel}>Use our journal recommendation tool to find the most appropriate journal for your article</p>
					</div>
					<div ref={tabRef}>
						{/* <button className={styles.linkButton} onClick={() => {sideScroll(tabRef.current, 25, 100, -10)}}>
							<FontAwesomeIcon icon={faAngleLeft}/>
						</button>
						<button className={styles.linkButton} onClick={() => {sideScroll(tabRef.current, 25, 100, 10)}}>
							<FontAwesomeIcon icon={faAngleRight}/>
						</button> */}
						<Tabs activeKey={activeTab} onSelect={(k) => changeActiveTab(k)} className={styles.customTabset}> 
							<Tab id="similarArticlesTab" eventKey='similarArticles' title='By Similar Articles' tabClassName={classNames(activeTab === 'similarArticles' ? styles.customActiveTab : styles.customTab)}>
								<h6 className='mb-0 mt-2'>OrthoPublish will help you find the best journal match for your paper based on keyword results. Enter your title and abstract and our algorithm will suggest suitable journals based on the context of the content, matching this to previously published similar articles.</h6>
								<Row className='mt-3'>
									<Col>
										<label htmlFor="password">Title Search <span className="text-danger">*</span></label>
										<InputGroup className="mb-1">
											<FormControl
												className={styles.customFormField}
												aria-label="Journal Title"
												name="title"
												value={litSearchFormValues.title ? litSearchFormValues.title : ''}
												placeholder="Enter the title of the manuscript"
												onChange={event => handleLitFormChange(event)}
												onBlur={event => handleLitFormValidation(event)}
												disabled={selectedSearch !== 'titleAbstract'}
												aria-describedby="basic-addon2"
												autoComplete="off"
												// onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
											/>
										</InputGroup>
										<span className={styles.formErrors}>{litSearchFormErrors.title}</span>

										<label htmlFor="abstract">
											<span  className='mr-2'>Abstract Search <span className="text-danger">*</span></span>
											{/* <EditingSuggestion getSuggestion={getSuggestion} /> */}
										</label>
										<InputGroup className="mb-1">
											<FormControl
												as="textarea"
												// onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
												rows="6"
												className={classNames(styles.customFormField,"textarea-element")}
												aria-label="Abstract"
												name="abstract"
												value={litSearchFormValues.abstract ? litSearchFormValues.abstract : ''}
												placeholder="An abstract is required to produce the most relevant match"
												onChange={event => handleLitFormChange(event)}
												onBlur={event => handleLitFormValidation(event)}
												disabled={selectedSearch !== 'titleAbstract'}
												aria-describedby="basic-addon2"
												// data-wsc-autocreate="true"
											/>
										</InputGroup>
										<span className={styles.formErrors}>{litSearchFormErrors.abstract}</span>

									</Col>
									
								</Row>
							</Tab>
							<Tab id="sfindJournalTab" eventKey='findJournal' title='By Journal' tabClassName={classNames(activeTab === 'findJournal' ? styles.customActiveTab : styles.customTab)}>
								<h6 className='mb-0 mt-2'>Enter keywords to find a journal with a scope that suits your paper and narrow down your search by selecting your preferred impact factor. If you know the journal you want to publish in, enter its name to be taken to the relevant formatting tool.</h6>
								<Row className='mt-3'>
									<Col md='6'>
										<label htmlFor="password">Journal name/ISSN/EISSN</label>
										<CustomeAutoComplete
										name ={'journal_title'}
										placeholder='Enter the name or ISSN/EISSN of the journal you are looking for'
										searchValue={journalSearchFormValues.journal_title} 
										setSearchValue={handleSearchFormChange} 
										handleSelect={handleSelect}
										optionList={journalListOption}
										getOptions={getJournalName}
										getEnterSearch={getJournalSearch}
										loading={loading}
										/>
										<span className={styles.formErrors}></span>
										<div>
											<label>Impact factor range</label>
											{isImpactFactorLoading?
											<ProgressLoader />
											:<SliderBar getDateRange={getCitationScoreRange} domain={citationDomain} fromRange={journalSearchFormValues?.impact_factor[0]} toRange={journalSearchFormValues?.impact_factor[1]} />}
											<span className={styles.formErrors}></span>
										</div>

									</Col>
									<Col md='6'>
										<label htmlFor="password" className='mr-2'>Keywords</label>
										<OverlayTrigger
											key="journalKeywords"
											placement="right"
											overlay={
												<Tooltip id={`tooltip-journal-keywords`}>
													Keywords entered here will be matched to journal scope to help you find the best match. Journal scopes are published on journal websites and may use broad terms. If your keywords do not produce matches please broaden your search terms, e.g. instead of 'radial fracture' use 'radius' or 'wrist, hand'. (Type keywords separated by commas.)
												</Tooltip>
											}
											>
											<FontAwesomeIcon icon={faInfoCircle} />
										</OverlayTrigger>
										<textarea
										// data-wsc-autocreate="true"
										className='form-control'
										name="journal_keyword"
										id="journal-textarea-element"
										rows={3}
										// onKeyUp={(event)=>handleKeyPressed13(event)}
										onKeyDown={(event)=>handleKeyPressed13(event)}
										placeholder="Type a keyword separated by comma"
										value={journalSearchFormValues.keywords}
										onChange={(e)=> {setJournalSearchFormValues({...journalSearchFormValues, keywords:e.target.value})}}
										/>
										<span className={styles.formErrors}></span>
										<div className='mb-3 d-flex flex-row align-items-center'>
											<input className="mx-2" type='checkbox' onChange={(e) => {handleTemplateCheckboxChange(e)}} checked={journalSearchFormValues.templateType == "1"}/>
											Display journals with templates only
										</div>
									</Col>
								</Row>
							</Tab>
							<Tab id="bibliographyTab" eventKey='bibliography' title='By Article' tabClassName={classNames(activeTab === 'bibliography' ? styles.customActiveTab : styles.customTab)}>
								<h6 className='mb-0 mt-2'>Enter your search terms to find out where your community is publishing.</h6>
								<Row className='mt-3'>
									<Col md='6'>
										<label htmlFor="authorName" className='mr-2'>Author name <TooltipButton tooltipMessage={"Enter the name of an author to find publications they have published in"} placement="right"><FontAwesomeIcon icon={faInfoCircle} /></TooltipButton></label>
										<InputGroup className="mb-1">
											<FormControl
												className={styles.customFormField}
												aria-label="authorName"
												name="authorName"
												value={bibliographyFormValues.authorName ? bibliographyFormValues.authorName : ''}
												placeholder="Enter author name"
												onChange={event => handleBibliographyFormChange(event)}
												onBlur={event => handleValidation(event)}
												onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
												aria-describedby="authorName"
												autoComplete="off"
											/>
										</InputGroup>
										<span className={styles.formErrors}>{bibliographyFormErrors.title}</span>

										<label htmlFor="keywords" className='mr-2'>Keywords</label>
										<OverlayTrigger
											key="keywords"
											placement="right"
											overlay={
												<Tooltip id={`tooltip-keywords`}>
													Keywords entered here will be matched to journal scope to help you find the best match. Journal scopes are published on journal websites and may use broad terms. If your keywords do not produce matches please broaden your search terms, e.g. instead of 'radial fracture' use 'radius' or 'wrist, hand'. (Type keywords separated by commas.)
												</Tooltip>
											}
											>
											<FontAwesomeIcon icon={faInfoCircle} />
										</OverlayTrigger>
										<textarea
										// data-wsc-autocreate="true"
										className='form-control'
										name="bibliography_keyword"
										id="article-textarea-element"
										// onKeyUp={(event)=>handleArticleKeyword(event)}
										onKeyDown={(event)=>handleKeyPressed13(event)}
										rows={5}
										placeholder="Type a keyword separated by comma"
										value={bibliographyFormValues.keywords}
										onChange={(e)=> setBibliographyFormValues({...bibliographyFormValues, keywords: e.target.value})}
										/>
										<span className={styles.formErrors}>{bibliographyFormErrors.keywords}</span>

									</Col>
									<Col md={6}>
										<label htmlFor="article_title" >Article title <TooltipButton tooltipMessage={" Enter the title of an article to find the publication it was published in"} placement="right"><FontAwesomeIcon icon={faInfoCircle} /></TooltipButton></label>
										
										<InputGroup className="mb-1">
											<FormControl
												className={styles.customFormField}
												aria-label="Article Title"
												name="article_title"
												value={bibliographyFormValues.article_title}
												placeholder="Enter the title of an article"
												onChange={event => handleBibliographyFormChange(event)}
												// onBlur={event => handleValidation(event)}
												onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
												aria-describedby="articleTitle"
												autoComplete="off"
											/>
										</InputGroup>
										<span className={styles.formErrors}>{bibliographyFormErrors.articleTitle}</span>

										<label htmlFor="institutionName">Institution name <TooltipButton tooltipMessage={"Enter the name of institution to find publications that authors from that institution have published in"} placement="right"><FontAwesomeIcon icon={faInfoCircle} /></TooltipButton></label>
										<InputGroup className="mb-1">
											<FormControl
												className={styles.customFormField}
												aria-label="Institution Name"
												name="institutionName"
												value={bibliographyFormValues.institutionName ? bibliographyFormValues.institutionName : ''}
												placeholder="Enter institution name"
												onChange={event => handleBibliographyFormChange(event)}
												onBlur={event => handleValidation(event)}
												onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
												aria-describedby="institutionName"
												autoComplete="off"
											/>
										</InputGroup>
										<span className={styles.formErrors}>{bibliographyFormErrors.institutionName}</span>

										<label htmlFor="pmid">PMID/DOI</label>
										<InputGroup className="mb-1">
											<FormControl
												className={styles.customFormField}
												aria-label="PMID"
												name="pmid"
												value={bibliographyFormValues.pmid ? bibliographyFormValues.pmid : ''}
												placeholder="Enter either PMID or DOI"
												onChange={event => handleBibliographyFormChange(event)}
												onBlur={event => handleValidation(event)}
												onKeyUp={(event)=>event.keyCode===13? getJournalSearch(event): null}
												aria-describedby="pmid"
												autoComplete="off"
											/>
										</InputGroup>
										<span className={styles.formErrors}>{bibliographyFormErrors.pmid}</span>
									</Col>
								</Row>
								
							</Tab>
						</Tabs>
					</div>
					<div>
						{loader ? 
							<button disabled className="px-5">
								Loading
								<Spinner animation='grow' size='sm'/>
								<Spinner animation='grow' size='sm'/>
								<Spinner animation='grow' size='sm'/>
							</button>
						: <button className="px-5" onClick={(event) => getJournalSearch(event)} >Search <FontAwesomeIcon icon={faMagnifyingGlass} className="h6 mb-0 pb-0 ml-2"/></button>}
						<button className={styles.linkButton} onClick={() => resetSearch()}>Reset Search</button>
					</div>
					{/* </form> */}
				</Card.Body>
			</Card>
			{
				journalList.length ? 
					<Row className='mt-3'>
						{didYouMeanList.length >0 && <Col md={{span: 9, offset: 3}} sm={12} className="mt-2">
						 	<div>
							 <span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(e,word)}>{word}</span>)}
							</div>
							<hr />
						</Col>}
						<Col md={{span: 9, offset: 3}} sm={12} className="mb-3 d-flex row justify-content-between align-items-center">
							<div className='col-md-6 col-sm-12 mt-2'><span className="h5">Showing {totalRecords} results</span></div>
							{type==="journal"?<div className='col-md-6 col-sm-12 mt-2 sort d-flex justify-content-end m-0 p-0'>
								<label className='h6 my-1 mr-3'>Sort by:</label>
								<select className="form-control-sm form-control w-50" value={sort} onChange={(e)=> handleSort(e)} >
									<option value="Rank">Relevance</option>
									<option value="publication_year">Most recent</option>
									<option value="journal_title_asc">Journal title (A-Z)</option>
									<option value="journal_title_desc">Journal title (Z-A)</option>
									<option value="impact_factor_desc">Impact factor (Highest first)</option>
									<option value="impact_factor_asc">Impact factor (Lowest first)</option>
								</select>
							</div>:null}
						</Col>
						<Col md={3}>
							<Facets isloading={loader} isRefreshed={facetsRefresh} oaFacets={accessTypeFacets} filtersApplied={getAppliedFilters} filterType="ACCESS TYPE" />
						</Col>
						<Col md={9}>
							{journalList.map((journal, i) => (
								<JournalInfo journalInfo={journal} isloading={loader} key={i} onClickChange={handleConfirmationModal} />
							))}
								<Pagination
									className="pagination-bar bg-white p-2"
									currentPage={currentPage}
									totalCount={totalRecords}
									pageSize={offset}
									onPageChange={(page) => setCurrentPage(page)}
									handleGoToPagination={handleGoToPagination}
									pageNo={pageNo}
									setPageNo={setPageNo}
								/>
						</Col>

					</Row>
				:  (resultsNotFound) ? <>
				{didYouMeanList.length >0 && <Col md={12} className="my-3 px-0">
						 	<div>
								<span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(e,word)}>{word}</span>)}
							</div>
				</Col>}
				<Card className='mt-3'>
					<Card.Body>                    
						<h4 className='text-secondary'>{resultsNotFound}</h4>
						<p className="my-2">- {languageError?languageError :"Try different keywords"}</p>
						<p className="mb-2">- Please check your spellings</p>
					</Card.Body>
				</Card> </>: null
			}
			{
				articleList.length ? 
				<div>
					<Row className='mt-3'>
						{didYouMeanList.length >0 && <Col md={12} className="mt-3">
						 	<div>
							 <span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(e,word)}>{word}</span>)}
							</div>
							<hr />
						</Col>}
						<Col md={12} className="row d-flex justify-content-between align-items-center mx-0">
							<div className='col-md-6 col-sm-12 px-0'>
							<span className="h5">Showing {totalRecords} results</span>
							</div>
							<div className='sort d-flex col-md-6 col-sm-12 justify-content-end align-items-center px-0'>
								<label className='mr-2 mb-1 text-dark h6'>Sort by:</label>
								<select className="form-control form-control-sm w-50 " value={sort} onChange={(e)=> handleSort(e)} >
									<option value="">Relevance</option>
									<option value="pubdate">Most recent</option>
									<option value="article_title">Article title</option>
									{/* <option value="article_title_asc">Article title (A-Z)</option>
									<option value="article_title_desc">Article title (Z-A)</option> */}
								</select>
							</div>
						</Col>
						<Col md={12} className="my-3">
							<Pagination
								className="pagination-bar p-2"
								currentPage={currentPage}
								totalCount={totalRecords}
								pageSize={offset}
								onPageChange={(page) => setCurrentPage(page)}
								handleGoToPagination={handleGoToPagination}
								pageNo={pageNo}
								setPageNo={setPageNo}
							/>						
						</Col>
						</Row>
					<Row className=''>
					<Col md={12}>
						{articleList.map((article, i) => (
							<ArticleList info={article} isloading={loader} key={i} onClickChange={handleConfirmationModal} />
						))}
						<Pagination
							className="pagination-bar p-2"
							currentPage={currentPage}
							totalCount={totalRecords}
							pageSize={offset}
							onPageChange={(page) => setCurrentPage(page)}
							handleGoToPagination={handleGoToPagination}
							pageNo={pageNo}
							setPageNo={setPageNo}
						/>				
						</Col>
						</Row>
					</div>
				: (biblioResultsNotFound) ?<>
				{didYouMeanList.length >0 && <Col md={12} className="mt-2">
					<div>
						<span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(e,word)}>{word}</span>)}
					</div>
					<hr />
				</Col>}
				 <Card className='mt-3'>
					<Card.Body>                    
						<h4 className='text-secondary'>{biblioResultsNotFound}</h4>
						<p className="my-2">- Try different keywords</p>
						<p className="mb-2">- Please check your spellings</p>
				</Card.Body></Card> </>: null  
			}
				<DuplicateCheckModal
					show={show.isduplicateCheck}
					handleShow={setShow}
					getManuscriptData={getManuscriptData}
					manuscriptDetails={manuscriptDetails}
					journalTitle={activeJournal.journalTitle}
					force={force}
					handleForce={setForce}
					lexiconId={activeJournal.journalId}
					setLoader = {setLoader}
					setDefaultBtnLoader={setButtonLoader}
					pageUrl="search"
					genericTemplate = {activeJournal.genericTemplate}
				/>

				<ConfirmationModal
					show={show}
					setShow={setShow}
					loader={btnLoader}
					setLoader={setButtonLoader}
					createDefaultManuscript={createDefaultManuscript}
					genericTemplate = {activeJournal.genericTemplate}
					journalTitle = {activeJournal.journalTitle}
				/>

				<PaymentModal
					show={show}
					setShow={setShow}
					loader={btnLoader}
					setLoader={setButtonLoader}
					createDefaultManuscript={createDefaultManuscript}
					genericTemplate = {activeJournal.genericTemplate}
					journalTitle = {activeJournal.journalTitle}
				/>
			{loader ? <FullLoader /> : null}
		</div>
	)
}

export default JournalSearch;