import React, { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import styles from "./PaymentSuccess.module.scss"
import { useEffect } from "react";
import history from "../../history";
import { useParams } from "react-router-dom";
import AxiosService from "../../utils/AxiosService";
import classNames from "classnames";
import { toast } from "react-toastify";
import { FieldLoader } from "../../components/Loader/CommonLoader";

const PaymentSuccess = () => {
    const [show, setShow] = useState(true)
    const [counter, setCounter] = useState(5)
    const queryParams = new URLSearchParams(window.location.search)
    const params = window.location.search.split("?")[1].split("&")
    const currency = queryParams.get("currency")
    const journal = queryParams.get("journalName")
    const journalID = queryParams.get("journalId") ? queryParams.get("journalId") : "" 
    const manuscriptID = ""
    const templateType = queryParams.get("templateType") ? queryParams.get("templateType") : ""
    const clone = queryParams.get("clone") ? queryParams.get("clone") : false
    const [journalName, setJournalName] = useState("")
    const [fees, setFees] = useState("")
    const [manuscriptId, setManuscriptId] = useState("")
    const [transactionUpdated, setTransactionUpdated] = useState(false)
    const [modalControl, setModalControl] = useState({forceManuscriptModal: false, createManuscriptModal: false})
    const [loader, setLoader] = useState({createManuscriptLoader: false})
    const [manuscriptDetails, setManuscriptDetails] = useState({})
    const [newManuscript, setNewManuscript] = useState(false)
    const apiService = new AxiosService()
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    const createManuscript = (force) => {
        setLoader({...loader, createManuscriptLoader: true})
        if (clone) {
            const data = {
                id: manuscriptID,
                journalId: journalID,
                templateType: templateType
            }
            apiService.cloneManuscript(data).then((res) => {
                toast.success("Created successfully", {autoClose: 3000}) 
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            })
        } else {
            const body = {
                "journalId": journalID,
                "force": force,
                "templateType": templateType
            }
            apiService.createManuscript(body).then((res) => {
                toast.success("Created successfully", {autoClose: 3000})
                setManuscriptDetails(res)
                setNewManuscript(res.manuscriptId)
                setTransactionUpdated(true)
                setTimeout(() => {
                    history.push(`/manuscript-submission/${res.manuscriptId}`)
                }, 3000)
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                    setTransactionUpdated(true)
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                    setTransactionUpdated(true)
                }
            })
        }
    }

    useEffect(() => {
        if(transactionUpdated) {
            if(counter > 0) {
                setTimeout(() => {
                    setCounter(counter-1)
                }, 1000)
            } else {
                if(newManuscript) {
                    history.push(`/manuscript-submission/${newManuscript}`)
                } else {
                    history.push("/profile?tab=credits")
                }
            }
        }
    }, [counter, transactionUpdated ])

    useEffect(() => {
        let manuscriptId = ""
        let body = {
            journalId: "",
            templateType: "",
            purchaseType: "",
            sessionId: ""
        }
        params.map((item) => {
            let temp = item.split("=")
            if(temp[0] === "manuscriptId") {
                setManuscriptId(decodeURIComponent(temp[1]))
                manuscriptId = temp[1] 
            } else if (temp[0] === "fees") {
                setFees(decodeURIComponent(temp[1]))
            } else if (temp[0] === "journalName") {
                setJournalName(decodeURIComponent(temp[1])) 
            } else if (temp[0] === "purchaseType") {
                body.purchaseType = temp[1]
            } else if (temp[0] === "sessionId") {
                body.sessionId = temp[1]
            } else if (temp[0] === "templateType") {
                body.templateType = temp[1]
            } else if (temp[0] === "journalId") {
                body.journalId = temp[1]
            }
        })
        apiService.purchaseCredit(body).then((res) => {
            toast.success(res.message, {autoClose: 3000})
            if(journalID !== "false") {
                apiService.userPaymentProfile().then((response) => {
                    if(templateType === "Specific" && response.credit >= 2) {
                        setModalControl({...modalControl, createManuscriptModal: true})
                    } else if (templateType === "Generic" && response.credit >= 1) {
                        setModalControl({...modalControl, createManuscriptModal: true})
                    } else {
                        toast.error("You don't have succificient credits to create manuscript for " + journal, {autoClose: 3000})
                        setTransactionUpdated(true)
                    }
                })
            } else {
                setTransactionUpdated(true)
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.message, {autoClose: 3000})
            setTransactionUpdated(true)
        })
    },[])
    return (
        <div className="mt-3 d-flex justify-content-center my-5 py-5">
            <Card className="w-50" show={show} onHide={()=> setShow(true)}>
                <Card.Header className={classNames(styles.primaryText, 'border-bottom')}>
                    <div>
                        <Modal.Title className='d-flex h4 justify-content-center'>
                            Payment successful
                        </Modal.Title>
                        <span className="text-secondary">{journal ? journal : ""}</span>
                    </div>
                </Card.Header>
                <Card.Body className="">
                    <div>
                        <div className="d-none d-md-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column p-2">
                                <span className="small text-secondary">SUBMISSION FEES</span>
                                <h4>{currencySymbols[currency.toUpperCase()]+fees}</h4>
                                <span className="small text-secondary">incl. of taxes</span>
                            </div>
                            <div className="d-flex flex-column p-2">
                                <span className="ml-auto small text-secondary">Redirecting in</span>
                                <h4 className="ml-auto">{counter}</h4>
                                <span className="ml-auto small text-secondary">seconds</span>
                            </div>
                        </div>
                        <div className="d-sm-flex d-md-none flex-row align-items-center">
                            <div className="d-flex flex-column p-2">
                                <span className="text-center small text-secondary">SUBMISSION FEES</span>
                                <h4 className="text-center">{currencySymbols[currency.toUpperCase()]+fees}</h4>
                                <span className="text-center small text-secondary">incl. of taxes</span>
                            </div>
                            <div className="d-flex flex-column p-2">
                                <span className="text-center small text-secondary">Redirecting in</span>
                                <h4 className="text-center">{counter}</h4>
                                <span className="text-center small text-secondary">seconds</span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {/* Create Confirmation Modal */}
            <Modal show={modalControl.createManuscriptModal} onHide={() => {
                if (!loader.createManuscriptLoader) {
                    setModalControl({...modalControl, createManuscriptModal: false})
                    setTransactionUpdated(true)
                }
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Format manuscript?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You will be formatting a new manuscript for <strong>{journal}</strong>. Are you sure you want to proceed?
                    </p>
                    <section>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100 mb-2" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100 mb-2" onClick={() => createManuscript(false)}>Start now</button>
                        }
                        <button className={'w-100 mb-2 customButton'} disabled={loader.createManuscriptLoader} 
                            onClick={()=> {
                                setModalControl({...modalControl, createManuscriptModal: false})
                                setTransactionUpdated(true)
                                history.push("/select-journal?sort=journal_title_asc")
                            }}
                        >
                            Select a different journal
                        </button>
                    </section>
                </Modal.Body>
            </Modal>

            {/* Create Forced Confirmation Modal */}
            <Modal show={modalControl.forceManuscriptModal} onHide={() => {
                setModalControl({...modalControl, forceManuscriptModal: false, createManuscriptModal: false})
                setTransactionUpdated(true)
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Multiple drafts alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You have previously created <strong>{manuscriptDetails.count}</strong> submission drafts for this journal. Would you like to create a new submission or continue working on a previous draft?
                    </p>
                    <section>
                        <button className={'w-100 mb-2 customButton'} onClick={()=> {
                                setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                                history.push(`/my-dashboard?journalTitle=${encodeURIComponent(journal)}&status=Draft`)
                            }}
                        >
                            Continue working on a draft
                        </button>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100" onClick={() => {createManuscript(true)}}>Create a new submission</button>
                        }   
                    </section>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PaymentSuccess