import { faTrash, faInfoCircle, faSort, faChevronDown, faChevronUp, faPencil, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, FormControl, Modal, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion"
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import Select from 'react-select';
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import LocalStorageService from "../../../../../utils/auth_service/AuthServiceStorage";
import AxiosService from "../../../../../utils/AxiosService";
// import  "../../MultiStepTabView.scss";
import style from "../StepFourForm/StepFourForm.module.scss"
import { CustomToggle } from "../RemirrorEditor/RemirrorEditorStep";
import AuthorForm from "./AuthorForm";
import { ToWords } from "to-words"
import { useSelector } from "react-redux";


const StepFourForm = ({manuscriptId, handelError, journalId, mandatoryFieldSet, handleMandatoryCheck}) => {
		const apiService = new AxiosService();
		const [formValues, setFormValues] = useState({
			first_name: "",
			last_name: "",
			group_name: "",
			email: "",
			orcid: "",
			affiliation:"",
			authorType: [],
			phone: "",
			contribution: [],
			authorStatment: ""
		});
		const [formErrors, setFormErrors] = useState({
				first_name: "",
				last_name: "",
				group_name: "",
				email: "",
				orcid: "",
				affiliation:"",
				authorType: [],
				phone: "",
				contribution: [],
				authorStatment: ""
		})
		const [updateAuthor, setUpdateAuthor] = useState()
		const validEmailRegex = RegExp(
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
		);
		const [isLoading, setIsLoading] = useState({btnAddAuthor: false, btnRemove: false});
		const [isListLoading, setIsListLoading] = useState(false);
		const [authorOptions, setAuthorOptions] = useState([]);
		const [authorList, setAuthorList] = useState([]);
		const [selectedAuthor, setSelectedAuthor] = useState();
		const [conflictOfInterest, setConflictOfInterest] = useState(false)	
		const [language, setLanguage] = useState("")	
		const [authorContributionOptions, setAuthorCOntributionOptions] = useState([])
		const [authorLimitExceedModal, setAuthorLimitExceedModal] = useState(false)
		const [statements, setStatements] = useState({data: "", error: ""})
		const [ethicalReviewCommitteeStatement, setEthicalReviewCommitteeStatement] = useState("")
		const [conflictOfInterestStatement, setConflictOfInterestStatement] = useState("")
		const [multivalue, setMultiValue] = useState([])
		const [editAuthorModal, setEditAuthorModal] = useState(false)
		const [authorListVisible, setAuthorListVisible] = useState()
		const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/);
		const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
		const noChar = RegExp(/^[^a-zA-Z]*$/)
		const [listOpen, setListOpen] = useState(true)
		const authorRef = useRef(null)
		const toWords = new ToWords({localeCode: "en-GB"})
		const journalConfig = useSelector(store => store.journalConfig.config)
		
		const handleChange = (event) => {
			event.preventDefault();
			let { name, value } = event.target;
			setFormValues({...formValues, [name]: value})
		}

		const handleValidation = (event) => {
			const {name, value} = event.target;
			typeof value === "string" ?
				setFormValues({...formValues, [name]: value.trim()})
			:
				setFormValues({...formValues, [name]: value})
			switch (name) {
				case "email":
					setFormErrors({...formErrors,
						email: value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "* Email is required"
					});
					break;
				case "authorType":
					setFormErrors({...formErrors,
						authorType: value?.trim().length > 0 ? "" : "* Role is required"
					});
					break;
				case "first_name":
					setFormErrors({...formErrors,
						first_name: value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted":value?.trim().length>15? "First Name must be max. 15 characters": ""
					});
					break;
				case "last_name":
					setFormErrors({
						...formErrors,
						last_name:   value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.trim().length>15? "Last Name must be max. 15 characters": ""
					});
					break;
				case "affiliation":
					setFormErrors({...formErrors,
						affiliation: value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required"
					});
					break;
				case "phone":
					let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
					setFormErrors({...formErrors,
						phone: trimedPhoneVal.length>0 && trimedPhoneVal.length < 7 ?  "Phone number must be min. 7 digit." : formValues?.phone?.length > 0 ? !validPhoneNo.test(trimedPhoneVal) ? "Enter valid phone number" : !noChar.test(trimedPhoneVal) ? "Phone numbers must not include any alphabetical characters" : "" : ""
					});
					break;
				case "contribution":
					setFormErrors({...formErrors,
						contribution: value.length <= 0 ? "* Contribution is required" : ""
					})
					break;
				default:
							break;
			}
		}

		const resetFormValues = () => {
			setFormValues({
				...formValues, 
				first_name: "",
				last_name: "",
				email: "",
				orcid: "",
				affiliation:"",
				authorType: [],
				phone: "",
				contribution: []
			})
			setMultiValue([])
		}

		const submitAuthor = (e) => {
			e.preventDefault();
			if(formErrors.phone || formErrors.first_name || formErrors.last_name || formErrors.email) {
				toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
			}else{
				if(formValues.email?.trim() && validEmailRegex.test(formValues.email) && formValues.authorType?.trim() && formValues.first_name?.trim().length >1 && formValues.last_name?.trim().length >0 && formValues.affiliation?.trim().length >2 && formValues.contribution.length > 0) {	
					setIsLoading({...isLoading, btnAddAuthor :true})
					if(mandatoryFieldSet?.authors[0]?.limit && authorList.length >= mandatoryFieldSet?.authors[0]?.limit && authorLimitExceedModal === false) {
						setAuthorLimitExceedModal(true)
						return
					} else {
						let trimedFormaValue = {
							"affilliation": formValues.affiliation?.trim(),
							"authorType": formValues?.authorType ? formValues?.authorType : "",
							"emailId": formValues.email?.trim(),
							"firstName": formValues.first_name?.trim(),
							"lastName": formValues.last_name?.trim(),
							"orcid": formValues.orcid?.trim(),
							"phone": formValues.phone?.trim()? formValues.phone?.trim() : "",
							"conflictOfInterest": conflictOfInterest,
							"contribution": formValues?.contribution ? formValues?.contribution: "",
						}
						apiService.addAuthor(manuscriptId, trimedFormaValue).then(res => {
								resetFormValues();
								setConflictOfInterest(false)
								setIsLoading({...isLoading, btnAddAuthor :false});
								toast.success("Saved successfully.",  {
									autoClose: 3000,
								})
								getAuthorList();
						}).catch(e=> {
								setIsLoading({...isLoading, btnAddAuthor :false});
								if(e.response){
								toast.error(e.response.data.message,  {
									autoClose: 3000,
								})
								}
						})
					}
				} else {
					window.scrollTo({top: 0, behavior: 'smooth'});
					setIsLoading({...isLoading, btnAddAuthor :false});
					toast.error("Form submission contains errors - please check and correct.",  {
						autoClose: 3000,
					})
					setFormErrors({
						...formErrors,
						email: formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required',
						first_name: formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"",
						last_name: formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "",
						affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
						authorType: formValues.authorType?.trim()?.length ===0 ?  '* Role is required': "",
						contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
					})	
				}	
			}	
		}

		
		const handleDeleteAuthor = (author) => {
			setSelectedAuthor(author);
			setIsLoading({...isLoading, btnRemove: true});
			const body = {
				"emailId": author.emailId
			}
			apiService.deleteAuthor(manuscriptId, body).then(res => {
				setIsLoading({...isLoading, btnRemove: false});
				toast.success(res.result,  {
                    autoClose: 3000,
                });
				getAuthorList();

			}).catch(e=> {
				setIsLoading({...isLoading, btnRemove: false});
				toast.error(e.message)
				toast.error(e?.response?.data?.message,  {
                    autoClose: 3000,
                })
				getAuthorList();
			})
		}
		
		const getAuthorList = () => {
				setIsListLoading(true);
				apiService.getAuthorList(manuscriptId).then(res=>{
						setIsListLoading(false);
						if(res.result.length ===0){
							setData()
							setListOpen(false)
						}
						if(res.result.length > 0) {
							setListOpen(true)
						}
						handelError();
						setAuthorList(res.result);
				}).catch(e => {
						setIsListLoading(false);
						toast.error(e?.response?.data?.message, {autoClose: 3000})
					})
		}

		const getAuthorOptions = () => {
				apiService.getDropdownOptions("author", journalId,manuscriptId).then(res=> {
					let tempOptions = []
					res.result.map((item) => {
						let tempObj = {value: decodeHtmlEntites(item.name), label: decodeHtmlEntites(item.name)}
						tempOptions.push(tempObj)
					})
					setAuthorOptions(tempOptions);
				})
		}

		const setData = () => {
			const localStorageService = LocalStorageService.getService();

			const authDetails = JSON.parse(localStorageService.getAuthDetails());
			setFormValues({
					...authDetails.metaData,
					authorType: "",
					contribution: [],
					group_name: "",
					authorStatment: ""
			});
			setConflictOfInterest(false)
		}

		function handleNumberChange(event){
			event.preventDefault();
			const {name, value} = event.target
			if(event.target.validity.valid){
				setFormValues({...formValues,[name]: value})
			}
			else if (value === '' || value === '-') {
				setFormValues({...formValues,[name]: value})
			}
		}

		const getData = () => {
			apiService.getStepOneDetails(manuscriptId).then(
				res => { 
					const data= res.result;
					handleMandatoryCheck(data?.manuscriptType)
					setLanguage(data?.languages?.[0])
				}).catch(e=> {
					toast.error(e?.response?.data?.message, {autoClose: 3000})
				})
		}

		const getAuthorContributions = () => {
			apiService.getDropdownOptions("authorContribution", journalId, manuscriptId).then((res) => {
				let tempOptions = []
				res.result.map((item) => {
					let tempObj = {value: decodeHtmlEntites(item.name), label: decodeHtmlEntites(item.name)}
					tempOptions.push(tempObj)
				})
				setAuthorCOntributionOptions(tempOptions)
			}, (err) => {
				console.log("Error: Step Four Form => getAuthorContributions: ", err)
			})
		}

		const handleChangeOnStatements = (e) => {
			setStatements(e.target.value)
		}

		const handleChangeOnEthicalReviewCommitteeStatement = (e) => {
			setEthicalReviewCommitteeStatement(e.target.value)
		}

		const handleChangeOnConflictOfInterestStatement = (e) => {
			setConflictOfInterestStatement(e.target.value)
		}

		const getStatements = () => {
			apiService.getStatements(manuscriptId).then((res) => {
				setStatements(res.result)
			}, (err) => {
				console.log(err)
			})
		}

		const getEthicalReviewCommitteeStatement = () => {
			apiService.getEthicalReviewCommitteeStatement(manuscriptId).then((res) => {
				setEthicalReviewCommitteeStatement(res.result)
			}, (err) => {
				toast.error(err?.response?.data?.message)
				console.log(err)
			})
		}

		const getConflictOfInterestStatement = () => {
			apiService.getConflictOfInterestStatement(manuscriptId).then((res) => {
				setConflictOfInterestStatement(res.result)
			}, (err) => {
				toast.error(err?.response?.data?.message)
				console.log(err)
			})
		}

		const saveStatements = (e) => {
			e.preventDefault()
			if(e.target.value.length > 0 && e.target.value.trim().length <= 0) {
				toast.error("Only white space not accepted!", {autoClose: 3000})
			} else {
				const data = {
					statements : e.target.value
				}
				apiService.addStatements(manuscriptId, data).then((res) => {
					toast.success(res.result, {autoClose: 3000})
				}, (err) => {
					toast.error(err?.response?.data?.message)
					console.log("Error in saving statement: ", err)
				})
			}
		}

		const saveEthicalReviewCommitteeStatements = (e) => {
			e.preventDefault()
			if(e.target.value.length > 0 && e.target.value.trim().length <= 0) {
				toast.error("Only white space not accepted!", {autoClose: 3000})
			} else {
				const data = { 
					ercStatements : e.target.value
				}
				apiService.addEthicalReviewCommitteeStatement(manuscriptId, data).then((res) => {
					toast.success(res.result, {autoClose: 3000})
				}, (err) => {
					toast.error(err?.response?.data?.message)
					console.log("Error in saving statement: ", err)
				})
			}
		}

		const saveConflictOfInterestStatement = (e) => {
			e.preventDefault()
			if(e.target.value.length > 0 && e.target.value.trim().length <= 0) {
				toast.error("Only white space not accepted!", {autoClose: 3000})
			} else {
				const data = {
					coiStatements : e.target.value
				}
				apiService.addConflictOfInterestStatement(manuscriptId, data).then((res) => {
					toast.success(res.result, {autoClose: 3000})
				}, (err) => {
					toast.error(err?.response?.data?.message)
					console.log("Error in saving statement: ", err)
				})
			}
		}

		const decodeHtmlEntites = (rawStr) => {
			let textarea = document.createElement('textarea')
			textarea.innerHTML = rawStr
			return textarea.value
		}

		const editAuthorButtonClick = (author) => {
			setUpdateAuthor({
				id: author.authorId,
				first_name: author.firstName,
				last_name: author.lastName,
				group_name: author.groupName,
				email: author.emailId,
				orcid: author.orcid,
				phone: author.phone,
				authorType: author.authorType,
				contribution: author.contribution,
				affiliation: author.affilliation,
				authorStatment: author.authorStatment
			})
			setEditAuthorModal(true)
		}

		useEffect(()=> {
			getData()
			getAuthorList();
			getAuthorOptions();
			getAuthorContributions();
			getStatements();
			if(journalConfig?.ethicalReviewCommitteeStatementRequired === "yes") {
				getEthicalReviewCommitteeStatement();
			}
			if(journalConfig?.conflictOfInterestStatementRequired === "yes") {
				getConflictOfInterestStatement();
			}
		},[])

		useEffect(() => {
			if(authorList.length && !authorListVisible) {
				setAuthorListVisible(true)
			} else {
				setAuthorListVisible(false)
			}
		}, [authorList])

		return (
			<div>
				<section className="">
					<h5>Your details</h5>
					<p className="">For each author/group, please enter a name in the appropriate fields (either an author name or a group name). Please click ‘Save Author’ after entering the information to add any contributors on the article title page. Your exported title page will include title and authors. Please check your chosen journal’s requirements to make sure you add all additional information required.</p>
				</section>
				
				<AuthorForm 
					authorContributionOptions={authorContributionOptions}
					authorOptions={authorOptions}
					getAuthorList={getAuthorList}
					authorLimitExceedModal={authorLimitExceedModal}
					setAuthorLimitExceedModal={setAuthorLimitExceedModal}
					manuscriptId={manuscriptId}
					mandatoryFieldSet={mandatoryFieldSet}
					authorList={authorList}
					prefillData={formValues}
					modal={false} 
					submitButtonText="Save Author"
					setExceedAuthor={setFormValues}
					isModalAddAuthorBtn={isLoading?.btnAddAuthor}
					ref={authorRef}
					groupNameOption={false}
				/>
				<div>
					<Accordion activeKey={listOpen ? "0" : undefined}>
						<Card>
							<CustomToggle eventKey="0">
								<Card.Header 
									className='flex-between cursor-pointer bg-white' 
									onClick={(e) => {
										e.preventDefault()
										setAuthorListVisible(!authorListVisible)
										setListOpen(!listOpen)
									}}
								>
									Author List {mandatoryFieldSet?.authors[0]?.limit ? `(Max: ${authorList.length}/${mandatoryFieldSet?.authors[0]?.limit})` : `(${authorList.length})`}
									<FontAwesomeIcon icon={authorListVisible ? faChevronUp : faChevronDown} />
								</Card.Header>
							</CustomToggle>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<Table responsive className="overflow-auto mt-2 border-bottom">
										<thead className="tableHead">
											<tr>
												<th width="25%">AUTHOR/GROUP NAME</th>
												<th>DETAILS</th>
												<th>CONTACT</th>
												<th className="text-right">ACTIONS</th>
											</tr>
										</thead>
										<tbody className="tBody">
												{ isListLoading ? 
													<tr>
														<td className="text-center" colSpan={3}>
														<CircleLoader />
														</td>
													</tr>  : authorList.length !==0  ?
													authorList.map(author => (
													<tr key={author.emailId}>
														<td>
															<p className={classNames(style.authorName, "mb-1 text-secondary")}>{author.firstName} {author.lastName}</p>
															<p className={classNames(style.authorName, "mb-1 text-secondary")}>{author.groupName}</p>
															{author.authorType && <p className="mb-1 text-secondary">{author?.authorType.join(", ")}</p>}

														</td>
														<td className="align-middle">
															{author.affilliation && <p className="mb-1 text-secondary">Company/Institution: {author.affilliation}</p>}
															{author.contribution && <p className="mb-1 text-secondary">Contribution: {author?.contribution.join(", ")}</p>}
															{author.orcid && <p className="mb-1 text-secondary"><span>ORCID ID: </span>{author.orcid}</p>}
															{author.authorStatment && <p className="mb-1 text-secondary"><span>Author Statment: </span>{author.authorStatment}</p>}
														</td>
														<td>
															{author.emailId && <p className="mb-1 text-secondary">Email: {author.emailId}</p>}
															{author.phone && <p className="mb-1 text-secondary">Phone: {author.phone}</p>}
														</td>
														<td className="py-1 align-middle d-flex flex-row">
														{ isLoading.btnRemove && selectedAuthor.emailId === author.emailId ?
															<>
																<button className="btn btn-md border-primary text-primary float-right my-1 mr-2"><CircleLoader size={"sm"}/></button>
																<button className="btn btn-md border-danger text-danger float-right my-1"><CircleLoader size={"sm"}/></button>
															</>
														:
															<>
																<button className={classNames(style.primaryBtn,"float-right my-1 mr-2")} onClick={()=> editAuthorButtonClick(author)}><FontAwesomeIcon  icon={faPencil} /></button>
																<button className="btn btn-md border-danger text-danger float-right my-1" onClick={()=> handleDeleteAuthor(author)}><FontAwesomeIcon  icon={faTrash} /></button>
															</>
														}	
														</td>
													</tr>
												)) :  
												<tr>
												<td colSpan={3} className="text-center">Data not found</td>
											</tr>
										}
										</tbody>
									</Table>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</div>
				<div className="my-3">
					<label>
						Statements
						<TooltipButton tooltipMessage="Please copy and paste in any mandatory statements required by your chosen journal. These will be added to your title page. No changes will be made to these." placement="top">
							<FontAwesomeIcon icon={faInfoCircle} className={classNames(style.primaryFont, "mx-2 align-middle")} />
						</TooltipButton>
					</label>
					<FormControl
						as="textarea"
						value={statements}
						rows={5}
						placeholder="Please copy and paste in any mandatory statements required by your chosen journal. These will be added to your title page. No changes will be made to these."
						onChange={(e)=> handleChangeOnStatements(e)}
						onBlur={(e)=> saveStatements(e)}
						area-label="Statements"
						name="statements"
					/>
				</div>
				{journalConfig?.ethicalReviewCommitteeStatementRequired === "yes" ?
					<div className="my-3">
						<label>
							Ethical Review Committee Statement
							<TooltipButton tooltipMessage="Please copy and paste in any mandatory ethical review committee statement required by your chosen journal. These will be added to your title page. No changes will be made to these." placement="top">
								<FontAwesomeIcon icon={faInfoCircle} className={classNames(style.primaryFont, "mx-2 align-middle")} />
							</TooltipButton>
						</label>
						<FormControl
							as="textarea"
							value={ethicalReviewCommitteeStatement}
							rows={5}
							placeholder="Please copy and paste in any mandatory ethical review committee statement required by your chosen journal. These will be added to your title page. No changes will be made to these."
							onChange={(e)=> handleChangeOnEthicalReviewCommitteeStatement(e)}
							onBlur={(e)=> saveEthicalReviewCommitteeStatements(e)}
							area-label="ethicalReviewCommitteeStatement"
							name="ethicalReviewCommitteeStatement"
						/>
					</div>
				:
					null
				}
				{journalConfig?.conflictOfInterestStatementRequired === "yes" ? 
					<div className="my-3">
						<label>
							Conflict of Interest Statement
							<TooltipButton tooltipMessage="Please copy and paste in any mandatory conflict of interest statement required by your chosen journal. These will be added to your title page. No changes will be made to these." placement="top">
								<FontAwesomeIcon icon={faInfoCircle} className={classNames(style.primaryFont, "mx-2 align-middle")} />
							</TooltipButton>
						</label>
						<FormControl
							as="textarea"
							value={conflictOfInterestStatement}
							rows={5}
							placeholder="Please copy and paste in any mandatory conflict of interest statement required by your chosen journal. These will be added to your title page. No changes will be made to these."
							onChange={(e)=> handleChangeOnConflictOfInterestStatement(e)}
							onBlur={(e)=> saveConflictOfInterestStatement(e)}
							area-label="conflictOfInterestStatement"
							name="conflictOfInterestStatement"
						/>
					</div>
				:
					null
				}
				<Modal show={authorLimitExceedModal}  dialogClassName="" onHide={() => {
					setAuthorLimitExceedModal(false)
					setIsLoading({...isLoading, btnAddAuthor :false})
				}}>
					<Modal.Header  className="h6">Add author warning!</Modal.Header>
					<Modal.Body className="pt-0">
						<p>This journal has a limit of {mandatoryFieldSet?.authors[0]?.limit ? toWords.convert(mandatoryFieldSet?.authors[0]?.limit).toLowerCase() : ""} authors. You will exceed this number if you add further authors. </p>
						<button className="btnOutline w-100 mt-2" 
							onClick={(e) => {
								e.preventDefault()
								setIsLoading({...isLoading, btnAddAuthor :false})
								setAuthorLimitExceedModal(false)
							}}
						>
							Cancel
						</button>
						<button className="w-100 mt-2"
							onClick={(e) => {
								setAuthorLimitExceedModal(false)
								setIsLoading({...isLoading, btnAddAuthor :true})
								authorRef.current.submitAuthor(e)
							}}
						>
							Add author
						</button>
					</Modal.Body>
				</Modal>
				<Modal show={editAuthorModal} size="xl" onHide={() => {
					setEditAuthorModal(false)
				}}>
					<Modal.Header className="d-flex align-items-center">
						Update author information
						<button class="btn btn-light" onClick={() => {setEditAuthorModal(false)}}>
							<FontAwesomeIcon  icon={faXmark}/>
						</button>
					</Modal.Header>
					<Modal.Body>
						<AuthorForm 
							authorContributionOptions={authorContributionOptions}
							authorOptions={authorOptions}
							getAuthorList={getAuthorList}
							authorLimitExceedModal={authorLimitExceedModal}
							setAuthorLimitExceedModal={setAuthorLimitExceedModal}
							manuscriptId={manuscriptId}
							mandatoryFieldSet={mandatoryFieldSet}
							authorList={authorList}
							prefillData={updateAuthor}
							modal={true}
							submitButtonText="Update author"
							closeModal={() => {setEditAuthorModal(false)}}
							groupNameOption={updateAuthor?.group_name ? true : false}
						/>
					</Modal.Body>
				</Modal>
			</div>
		);	
}

export default StepFourForm;
